import React from "react";
import { Link } from "react-router-dom";

const AddressCard = ({ address, deleteAddress }) => {
  return (
    <div className="col-sm-4 col-12">
      <div className="address-card card-shadow p-20">
        <i
          onClick={() => deleteAddress(address.address_id)}
          className="bi bi-trash text-gold fr font-22"
        ></i>
        <p className="font-18 text-description mb-10">
          {address.address_1}
          <br />
          {address.address_2}
          <br />
          {address.city}, {address.zone}, {address.country} - {address.postcode}
        </p>
        <Link to={`/editAddress/${address.address_id}`} className="text-gold">
          <i className="bi bi-pencil"></i> Edit Address
        </Link>
      </div>
    </div>
  );
};

export default AddressCard;
