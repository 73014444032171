import React from "react";
import { useContext } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import { CatalogContext } from "../../contexts";

const responsive = {
  mobile: {
    breakpoint: { max: 1950, min: 0 },
    items: 1.3,
  },
};

const BarewareMobile = () => {
  const { barWare } = useContext(CatalogContext);
  return (
    <section className="most-wanted dark-section mt-0">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <h1>Barware</h1>
            <p className="text-offwhite">
              Shop wildlife inspired suave barware
            </p>
          </div>
        </div>

        <div className="row">
          <div className="row">
            <div className="col-12 p-0">
              <Carousel
                responsive={responsive}
                showDots={false}
                arrows={true}
                className="trending-slider p-0"
              >
                {barWare.length > 0 ? (
                  <Link
                    to={`/product/${barWare[0].product_id}`}
                    className="mySlider-slide"
                  >
                    <img
                      src={barWare[0].original_image}
                      alt=""
                      className="img-fluid bareware-img"
                    />
                  </Link>
                ) : null}

                {barWare.length > 1 ? (
                  <Link
                    to={`/product/${barWare[1].product_id}`}
                    className="mySlider-slide"
                  >
                    <img
                      src={barWare[1].original_image}
                      alt=""
                      className="img-fluid bareware-img"
                    />
                  </Link>
                ) : null}
                {barWare.length > 2 ? (
                  <Link
                    to={`/product/${barWare[2].product_id}`}
                    className="mySlider-slide"
                  >
                    <img
                      src={barWare[2].original_image}
                      alt=""
                      className="img-fluid bareware-img"
                    />
                  </Link>
                ) : null}
                {barWare.length > 3 ? (
                  <Link
                    to={`/product/${barWare[3].product_id}`}
                    className="mySlider-slide"
                  >
                    <img
                      src={barWare[3].original_image}
                      alt=""
                      className="img-fluid bareware-img"
                    />
                  </Link>
                ) : null}
              </Carousel>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-6 offset-3 mt-20 text-center">
            <button className="btn btn-primary btn-gold w-100 mb-30">
              SHOP NOW
            </button>
            <br /> <br />
          </div>
        </div>
      </div>
    </section>
  );
};

export default BarewareMobile;
