import React, { useState, useRef } from "react";
import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CartContext, SessionContext } from "../../contexts";
import logo from "../../imgs/logo/logo_color.png";
import SearchForm from "./SearchForm";
import SideBar from "./SideBar";
import "./header.css";

function showSearch() {
  if (window.innerWidth < 1250) {
    document.getElementById("search-form").style.display = "flex";
  }
}

function menuOpen() {
  if (window.innerWidth < 750) {
    document.getElementById("side-bar").style.left = "0px";
    document.getElementById("sidebar-bg").style.display = "inline";
  }
}

const HeaderWhite = () => {
  const { currency, setCurrency } = useContext(SessionContext);
  const { cart } = useContext(CartContext);

  const [searchTerm, setSearchTerm] = useState("");

  const searchInput = useRef(null);

  const navigate = useNavigate();

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (window.innerWidth < 1250) {
      document.getElementById("search-form").style.display = "flex";
    } else if (searchInput.current.value) {
      navigate(`/search/${searchInput.current.value}`);
    }
  };

  return (
    <div className="header-bar header-fixed header-app dark-header">
      <SearchForm />
      <div className="container header-container">
        <div className="row d-flex align-items-center">
          <div className="col-2 col-sm-2">
            <Link to="../">
              <img src={logo} alt="" className="img-logo" />
            </Link>
          </div>
          <div className="col-sm-7 col-5">
            <nav className="desktop_nav">
              <Link to="../categories/62" className="golden-color">
                Barware
              </Link>
              <Link to="../categories/59" className="golden-color">
                Home and Decor
              </Link>
              <Link to="../categories/60" className="golden-color">
                Travel
              </Link>
              <Link to="../categories/63" className="golden-color">
                Jewellery
              </Link>
              <Link to="../categories/61" className="golden-color">
                Office
              </Link>
            </nav>
          </div>
          <div className="col-5 col-sm-3">
          <select
              value={currency}
              onChange={(e) => {
                setCurrency(e.target.value);
              }}
              className="currency_drop hide-phone"
            >
              <option value="INR">INR</option>
              <option value="USD">USD</option>
            </select>
            <i
              style={{ color: "#fff", marginRight: "0px" }}
              onClick={() => menuOpen()}
              className="bi bi-list cursor-pointer font-24 float-right mr-10 hide-desktop golden-color"
            ></i>
            &nbsp;&nbsp;
            <Link to="../Cart" className="golden-color">
              <i
                style={{ color: "#fff", position: "relative" }}
                className="bi bi-cart3 font-24 float-right mr-10 hide-phone golden-color"
              >
                <div className="cart-badge">{cart ? cart.length : 0}</div>
              </i>
              &nbsp;&nbsp;
            </Link>
            <Link to="../login" className="golden-color">
              <i
                style={{ color: "#fff" }}
                className="bi bi-person-circle font-20 float-right hide-phone mr-10 golden-color"
              ></i>
            </Link>
            <i
              style={{ color: "#fff" }}
              onClick={handleSearch}
              className="bi bi-search cursor-pointer font-18 float-right mr-10 golden-color"
            ></i>
            &nbsp;&nbsp;
            <input
              ref={searchInput}
              onChange={(e) => handleChange(e)}
              value={searchTerm}
              type="text"
              placeholder="Search"
              className="search-desktop"
            />
           
          </div>
        </div>
      </div>
      <SideBar />
    </div>
  );
};
export default HeaderWhite;
