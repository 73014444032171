import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../common/Header";
import FooterDesktop from "../common/FooterDesktop";
import { Link } from "react-router-dom";
import { CatalogContext, UserContext } from "../../contexts";
import Loading from "../common/Loading";

const Register = () => {
  const { signup, user, log } = useContext(UserContext);
  const { showToast } = useContext(CatalogContext);

  const [registration, setRegistration] = useState({
    firstname: "",
    lastname: "",
    email: "",
    telephone: "",
    password: "",
    agree: 1,
    confirm: "",
    fax: "",
  });
  const [buttonsDisabled, setButtonsDisabled] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (log === 1) {
      navigate("/account");
    }
  }, [user, log]);

  const handleChange = (e) => {
    const form = { ...registration };
    form[e.target.name] = e.target.value;
    if (e.target.name === "password") {
      form["confirm"] = e.target.value;
    }

    setRegistration(form);
  };

  const register = async () => {
    setButtonsDisabled(true);
    const keys = Object.keys(registration);
    let check = true;
    for (let i = 0; i < keys.length; i++) {
      if (registration[keys[i]] === "" && keys[i] !== "fax") {
        showToast("error", `${keys[i]} is required!`);
        setButtonsDisabled(false);
        return false;
      }
    }

    let registerForm = { ...registration };
    if (registerForm.fax === "") {
      delete registerForm.fax;
    }

    const response = await signup(registerForm);
    console.log("response", response);
    if (response && "success" in response && response.success === 1) {
      console.log("Account created", registration);
      showToast("success", "Account Created Successfully!");
      navigate("/account");
    } else {
      setButtonsDisabled(false);
      console.log("Sign up failed!", response);
      showToast("error", response.error[0]);
    }
  };
  return (
    <>
      <Header />
      {log === 0 ? (
        <div>
          <div className="section mh-70">
            <div className="row">
              <div className="col-12">
                <div className="login-card text-center login-box">
                  <h1>Register Account</h1>
                  <p className="text-description mb-0">
                    Register with us for exclusive benefits.
                  </p>
                  <input
                    onChange={(e) => handleChange(e)}
                    type="text"
                    name="firstname"
                    value={registration.firstname}
                    placeholder="First Name"
                    className="form-control "
                  />
                  <input
                    onChange={(e) => handleChange(e)}
                    type="text"
                    name="lastname"
                    value={registration.lastname}
                    placeholder="Last Name"
                    className="form-control "
                  />
                  <input
                    onChange={(e) => handleChange(e)}
                    type="email"
                    name="email"
                    value={registration.email}
                    placeholder="Email address"
                    className="form-control "
                  />
                  <input
                    onChange={(e) => handleChange(e)}
                    type="number"
                    name="telephone"
                    value={registration.telephone}
                    placeholder="Phone"
                    className="form-control "
                  />
                  <input
                    onChange={(e) => handleChange(e)}
                    type="password"
                    name="password"
                    value={registration.password}
                    placeholder="Password"
                    className="form-control "
                  />
                  <button
                    onClick={() => register()}
                    disabled={buttonsDisabled}
                    className="btn btn-primary btn-gold w-100 mt-20 mb-10"
                  >
                    {buttonsDisabled ? "Loading..." : "Sign Up"}
                  </button>
                  <br />
                  Already Registered?{" "}
                  <Link to="../login" className="text-description">
                    Login Now
                  </Link>
                </div>
              </div>
            </div>
            <br />
            <br />
          </div>
        </div>
      ) : (
        <Loading />
      )}
      <FooterDesktop page="/profile" />
    </>
  );
};

export default Register;
