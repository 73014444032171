import React from "react";
import Header from "../../common/Header";
import FooterDesktop from "../../common/FooterDesktop";
import CategoryCard from "./CategoryCard";
import { CatalogContext } from "../../../contexts";
import { useContext } from "react";
import Loading from "../../common/Loading";

const AllCategories = () => {
  const {
    jewelleryCategory,
    officeCategory,
    travelCategory,
    barwareCategory,
    homeAndDecorCategory,
  } = useContext(CatalogContext);
  console.log(jewelleryCategory);
  console.log(officeCategory);
  console.log(travelCategory);
  console.log(barwareCategory);
  console.log(homeAndDecorCategory);

  return (
    <div>
      <Header />
      <section className="section dark-bg ptb-20">
        <div className="container text-center mh-70">
          <div className="row">
            <div className="col-12 ">
              <h1 className="mt-100">Shop</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {jewelleryCategory ||
              officeCategory ||
              travelCategory ||
              barwareCategory ||
              homeAndDecorCategory ? (
                <>
                  {jewelleryCategory && (
                    <CategoryCard category={jewelleryCategory} />
                  )}
                  {officeCategory && <CategoryCard category={officeCategory} />}
                  {travelCategory && <CategoryCard category={travelCategory} />}
                  {barwareCategory && (
                    <CategoryCard category={barwareCategory} />
                  )}
                  {homeAndDecorCategory && (
                    <CategoryCard category={homeAndDecorCategory} />
                  )}
                </>
              ) : (
                <Loading />
              )}
            </div>
          </div>
        </div>
      </section>
      <FooterDesktop page="/AllCategories" />
    </div>
  );
};

export default AllCategories;
