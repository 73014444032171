import React from "react";
import loading from "../../imgs/loading.gif";
function Loading() {
  return (
    <div
      style={{
        height: "70vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img src={loading} alt="" style={{ width: "50px" }} />
    </div>
  );
}

export default Loading;
