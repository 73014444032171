import React from 'react'
import Header from '../common/Header';
import FooterDesktop from '../common/FooterDesktop';
import {Link} from 'react-router-dom';

const Forget = () => {
  return (
    <div>
        <Header />
        <div className="section mh-70">
            <div className="row">
                <div className="col-12">
                    <div className="login-card text-center login-box" >
                        <h1>Password Recovery</h1>
                        <p className="text-description mb-0">We will send recovery link to your registered email.</p>
                        <input type="email" placeholder="Your Email" className="form-control " />
                       <button className="btn btn-primary btn-gold w-100 mt-20 mb-10">Submit</button>
                      <br />
                       Already Registered? <Link to="../login" className="text-description">Login</Link>
                    </div>
                </div>
            </div>
        </div>
        <FooterDesktop page='/profile'/>
    </div>
  )
}

export default Forget