import React from "react";
import Header from "../../common/Header";
import FooterDesktop from "../../common/FooterDesktop";
import { Link } from "react-router-dom";

const OrderInfo = () => {
  return (
    <div>
      <Header />
      <div className="section mh-70">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h2 className="mt-120">Order Information</h2>
              <Link to="../account" className="back_btn text-description fl">
                {" "}
                <i className="bi bi-arrow-left-circle-fill"></i> Go Back
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-8 offset-sm-2 col-12 offset-0">
              <table className="table table-bordered table-hover mt-30">
                <thead>
                  <tr>
                    <td className="text-left" colSpan={2}>
                      Order Details
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-left" style={{ width: "50%" }}>
                      {" "}
                      <b>Order ID:</b> #83
                      <br />
                      <b>Date Added:</b> 30/03/2021
                    </td>
                    <td className="text-left" style={{ width: "50%" }}>
                      {" "}
                      <b>Payment Method:</b> Credit / Debit Card
                      <br />
                      <b>Shipping Method:</b> Free Shipping{" "}
                    </td>
                  </tr>
                </tbody>
              </table>
              <table className="table table-bordered table-hover">
                <thead>
                  <tr>
                    <td
                      className="text-left"
                      style={{ width: "50%", verticalAlign: "top" }}
                    >
                      Payment Address
                    </td>
                    <td
                      className="text-left"
                      style={{ width: "50%", verticalAlign: "top" }}
                    >
                      Shipping Address
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-left">
                      ronak Vaya
                      <br />
                      bapu nagar
                      <br />
                      S-61
                      <br />
                      Jaipur 302001
                      <br />
                      Andaman and Nicobar Islands
                      <br />
                      India
                    </td>
                    <td className="text-left">
                      ronak Vaya
                      <br />
                      bapu nagar
                      <br />
                      S-61
                      <br />
                      Jaipur 302001
                      <br />
                      Andaman and Nicobar Islands
                      <br />
                      India
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="table-responsive">
                <table className="table table-bordered table-hover">
                  <thead>
                    <tr>
                      <td className="text-left">Product Name</td>
                      <td className="text-left">Model</td>
                      <td className="text-right">Quantity</td>
                      <td className="text-right">Price</td>
                      <td className="text-right">Total</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-left"> Halo Pear Earrings </td>
                      <td className="text-left wrapped-up">
                        096410ER18100B01ZZZ
                      </td>
                      <td className="text-right">1</td>
                      <td className="text-right">$1.0000</td>
                      <td className="text-right">$1.0000</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={3} />
                      <td className="text-right">
                        <b>Sub-Total</b>
                      </td>
                      <td className="text-right">$1.0000</td>
                    </tr>
                    <tr>
                      <td colSpan={3} />
                      <td className="text-right">
                        <b>Free Shipping</b>
                      </td>
                      <td className="text-right">$0.0000</td>
                    </tr>
                    <tr>
                      <td colSpan={3} />
                      <td className="text-right">
                        <b>Total</b>
                      </td>
                      <td className="text-right">$1.0000</td>
                    </tr>
                  </tfoot>
                </table>
              </div>

              <div className="buttons clearfix">
                <div className="pull-right">
                  <Link to="../account">
                    <button className="btn btn-primary btn-gold">
                      Continue
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <FooterDesktop page="/profile" />
    </div>
  );
};

export default OrderInfo;
