import axios from'axios';


export default {

    apiEndpoint:"https://admin.codesilver.in/index.php?route=",
    apiEndpointShort:"https://admin.codesilver.in/api/rest/",
    url:"https://admin.codesilver.in/",

    headers:{
        "X-Oc-Merchant-Id": "12345",
        "Accept": "application/json",
    },

    get:axios.get,
    post:axios.post,
    put:axios.put,
    delete:axios.delete
}