import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { CatalogContext } from "../../contexts";

const HomeDecor = () => {
  const { homeAndDecor } = useContext(CatalogContext);
  return (
    <section className="most-wanted dark-section mt-0 ptb-30">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <h1>Home & Decor</h1>
            <p className="text-offwhite">Bring wilderness home</p>
          </div>
        </div>

        <div className="row">
          <div className="row-grid">
            <div className="column c4">
              {homeAndDecor.length > 0 ? (
                <Link to={`/product/${homeAndDecor[0].product_id}`}>
                  <img
                    src={homeAndDecor[0].original_image}
                    style={{ width: "100%" }}
                  />
                </Link>
              ) : null}
              {homeAndDecor.length > 1 ? (
                <Link to={`/product/${homeAndDecor[1].product_id}`}>
                  <img
                    src={homeAndDecor[1].original_image}
                    style={{ width: "100%" }}
                  />
                </Link>
              ) : null}
            </div>
            <div className="column c5">
              {homeAndDecor.length > 2 ? (
                <Link to={`/product/${homeAndDecor[2].product_id}`}>
                  <img
                    src={homeAndDecor[2].original_image}
                    style={{ width: "100%" }}
                  />
                </Link>
              ) : null}
              {homeAndDecor.length > 3 ? (
                <Link to={`/product/${homeAndDecor[3].product_id}`}>
                  <img
                    src={homeAndDecor[3].original_image}
                    style={{ width: "100%" }}
                  />
                </Link>
              ) : null}
            </div>
            <div className="column c6">
              {homeAndDecor.length > 4 ? (
                <Link to={`/product/${homeAndDecor[4].product_id}`}>
                  <img
                    src={homeAndDecor[4].original_image}
                    style={{ width: "100%" }}
                  />
                </Link>
              ) : null}
              {homeAndDecor.length > 5 ? (
                <Link to={`/product/${homeAndDecor[5].product_id}`}>
                  <img
                    src={homeAndDecor[5].original_image}
                    style={{ width: "100%" }}
                  />
                </Link>
              ) : null}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-6 offset-3 mt-20 text-center">
            <Link
              to={`/categories/59`}
              className="btn btn-primary btn-gold w-100 mb-30"
            >
              SHOP NOW
            </Link>
            <br /> <br />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="divider mt-30"></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeDecor;
