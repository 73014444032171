import React from "react";
import { Link } from "react-router-dom";

const ProductCard = ({ category }) => {
  return (
    <div className="col-sm-4 col-12 mt-10">
      <Link to={`/categories/${category.id}`}>
        <div className="card card-styled card-styled-dark text-center mt-20 product-card">
          <img src={category.original_image} alt="" className="img-fluid" />
          <p className="font-14 mb-0 mt-10 text-gold">
            <b>{category.name}</b>
          </p>
        </div>
      </Link>
    </div>
  );
};

export default ProductCard;
