import React from 'react'
import { Link } from 'react-router-dom'

const ProductCardWishlist = () => {
  return (
          <div className="col-sm-4 col-12">
           <Link to="../product"> 
            <div className="card card-styled card-styled-dark text-center mt-20 product-card">
              <img
                src="https://wild.codesilver.in/image/catalog/60/CSWLT015.jpg"
                alt=""
                className="img-fluid"
              />
              <p className="font-14 mb-0 mt-10 text-gold fr">
                <b>DEER FIGURINE</b>
              </p>
              <p className="mb-0 fl">$300</p>
            </div>
            </Link>
            <button className="btn btn-primary btn-gold mt-10 wishlist-btn fr">
              <i className="bi bi-lightning"></i>&nbsp;<font className="hide-phone">Buy Now</font>
            </button>
            <button className="btn btn-primary btn-gold btn-outline mt-10 fl-r wishlist-btn fl">
              <i className="bi bi-trash"></i>&nbsp;
              <font className="hide-phone">  Remove</font></button>
          </div>  
          
  )
}

export default ProductCardWishlist