import React from 'react'
import Header from "../common/Header";
import FooterDesktop from "../common/FooterDesktop";

const Terms = () => {
  return (
    <div>
    <Header />
    <section className="mh-70">
      <div className="container mb-30">
        <div className="row">
          <div className="col-12 text-center">
            <h1 className="mt-100">Privacy Policy</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
          <p className="font-16 text-description mt-20">
  This Privacy Policy sets out how codesilver.in uses and protects any
  information that you give when you use this website.
  <br />
  <br />
  Codesilver.in is committed to ensure that your privacy is protected. Should we
  ask you to provide certain information needed to identify you when using this
  website; you can rest assured that all the data provided will be treated
  exclusively in accordance with this Privacy Policy.
  <br />
  <br />
  Codesilver.in might change the Privacy Policy from time to time by updating
  this page. Therefore we kindly recommend you to check the webpage on regular
  basis to be sure that you completely agree with any of these changes. This
  Policy is effective from 1st Jan, 2015.
  <br />
  <br />
  <b>What we collect:</b>
  <br />
  We may collect the following information:
  <br />
  1. Name
  <br />
  2. Contact information including email address, postal address and phone
  number.
  <br />
  3. Demographic information such as postcode. <br />
  Note: We do not ask for credit card numbers or bank account details. These are
  the details that will be filled by you directly at the payment gateway on the
  respective bank's site.
  <br />
  <br />
  <b>What we do with the information we gather:</b>
  <br />
  We require this information to understand your needs and provide you with a
  better service, and in particular for the following reasons:
  <br />
  1. Internal record keeping.
  <br />
  2. We may use the information to improve our products and services.
  <br />
  3. From time to time, we may also use your information to contact you for
  market research purposes. We may contact you by email, phone, fax or mail. We
  may use the information to customize the website according to your interests.
  <br />
  <br />
  <b>Security:</b> <br />
  We are committed to ensuring that your information is secure. In order to
  prevent unauthorized access or disclosure, we have put in place suitable
  physical, electronic and managerial procedures to safeguard and secure the
  information we collect online.
  <br />
  <br />
  <b>Links to other websites</b>
  <br />
  Our website may contain links to other websites of interest. However, once you
  have used these links to leave our site, you should note that we do not have
  any control over that other website. Therefore, we cannot be responsible for
  the protection and privacy of any information which you provide whilst
  visiting such sites and such sites are not governed by this privacy statement.
  You should exercise caution and look at the privacy statement applicable to
  the website in question.
  <br />
  <br />
  <b>Controlling your personal information:</b> <br />
  1. We will never sell or lease your personal information to third parties. 2.
  If you believe that any information we are holding on you, is incorrect or
  incomplete, please contact us as soon as possible at the stated address. We
  will promptly correct any information found to be incorrect.{" "}
</p>

          </div>
        </div>
        
      </div>
    </section>
    <FooterDesktop />
  </div>
  )
}

export default Terms