import React from 'react'
import { ReactComponent as Globe } from './globe.svg';
import { ReactComponent as Returns } from './returns.svg';
import { ReactComponent as Secure } from './secure.svg';
import bg from './bg.png';

const Easy = () => {
  return (
    <section className='easy_returns' style={{backgroundImage: `url(${bg})`}}>
       <div className="container">
         <div className="row">
            <div className="col-sm-4  easy-returns-block text-center">
                <Globe />
                <h4 className="mb-0 ">Shipping Worldwide</h4>
                <p className="mb-0 font-13 mt-10">Fast and secure shipping accross globe</p>
            </div>
            <div className="col-sm-4  easy-returns-block text-center">
                <Returns />
                <h4 className="mb-0">Easy Returns</h4>
                <p className="mb-0 font-13 mt-10">Hassel free returns policy.</p>
            </div>
            <div className="col-sm-4 easy-returns-block text-center">
               <Secure />
                <h4 className="mb-0">Secure Payments</h4>
                <p className="mb-0 font-13 mt-10">256-bit encrypted safe payments.</p>
            </div>
         </div>
        </div>
    </section>
  )
}

export default Easy