import React from 'react'
import Header from "../common/Header";
import FooterDesktop from "../common/FooterDesktop";

const Terms = () => {
  return (
    <div>
    <Header />
    <section className="mh-70">
      <div className="container mb-30">
        <div className="row">
          <div className="col-12 text-center">
            <h1 className="mt-100 ">Terms & Conditions</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
          <p className="font-16 text-description mt-20">
  Terms and Conditions for the Supply of Goods through the website you are
  connected to the User Agreement for Code Silver, Emerald House, C-Scheme,
  Jaipur. This service is operated by Code Silver. Company Registration number -
  REG No.
  <br />
  <br />
  1. The Contract between you and us: We must receive payment in whole for the
  price of the goods that you order before your order can be accepted. Once
  payment has been received by us we will confirm that your order has been
  received by sending an email to you at the email address you provide in your
  order form. This is not an Order Acceptance from Code Silver. Our acceptance
  of your order takes place on the dispatch to you of the products ordered
  unless we notify you that we do not accept your order, or you have cancelled
  your order in accordance with clause. Our acceptance of your order brings into
  existence a legally binding contract between us. Only adults (persons aged 18
  and over) are entitled to enter into legally binding contracts and as a result
  are the only people entitled to transact through this site. Any other
  individuals who are unable to perform legally binding contracts are prohibited
  from ordering goods using this site. If you do not qualify do not use this
  site.
  <br />
  <br />
  2. Pricing, Delivery and Discount Codes:
  <br />
  2.1 The prices payable for goods that you order are as set out in our website.{" "}
  <br />
  2.1.1 All of our prices are in Indian Rupees. You will be billed in Indian
  Rupees and if you purchase from outside the India, currency fluctuations and
  credit card charges may make a difference to the amount billed on your credit
  card.
  <br />
  2.2 You may be required to pay extra for delivery and it might not be possible
  for us to deliver to some locations. Our delivery charges are set out in our
  website.
  <br />
  <br />
  3. Right for you to cancel your contract:
  <br />
  3.1 Subject to 3.3 below, you may cancel your contract with us for the goods
  you order at any time before dispatch of the product. Products once sold will
  not be return, in case of damage product received that can be replaced up to
  the end of the seventh working day from the date you receive the ordered
  goods. You do not need to give us any reason for cancelling your contract nor
  will you have to pay any penalty.
  <br />
  3.2 To cancel your contract you must notify us by email at info@codesilver.in
  or telephone us on +91-141-4045116.
  <br />
  3.3 Your right to cancel your contract with us for the goods you have ordered
  is subject to:
  <br />
  a) You calling us before dispatch of the goods.
  <br />
  b) The item(s) cannot be a Bespoke Special Order, which due to their unique
  nature, cannot be exchanged or returned under any circumstances.
  <br />
  3.4 Once you have notified us that you are cancelling your contract, any sum
  debited from your credit card will be credited to your account as soon as
  possible and in any event within 30 days of your order providedthat the goods
  in question are returned by you and received by us in the condition they were
  in when delivered to you. If you do not return the goods delivered to you, in
  the way detailed above, we shall be entitled to deduct the direct costs of
  recovering the goods from the amount to be credited to you.
  <br />
  <br />
  4. Cancellation by us:
  <br />
  4.1 We reserve the right to cancel the contract between you and us if:
  <br />
  4.1.1 We have insufficient stock to deliver the goods you have ordered;
  <br />
  4.1.2 We do not deliver to your area; or
  <br />
  4.1.3 One or more of the items you ordered was listed at an incorrect price
  due to a typographical error or an error in the pricing information set by us
  from our management.
  <br />
  4.2 If we do cancel your contract we will notify you by email or by telephone,
  and will re-credit to your account any sum deducted by us from your account as
  soon as possible but in any event within 30 days of your order. We will not be
  obliged to offer any additional compensation for disappointment suffered.
  <br />
  <br />
  <br />
  5. Delivery of goods to you:
  <br />
  5.1 We will deliver the goods ordered by you to the address you give us for
  delivery at the time you make your order. It is your responsibility to ensure
  that the delivery address you give us is correct and accurate and that there
  is somebody to sign for and take delivery of the goods from the delivery
  service used. <br />
  5.2 All orders within the India are delivered using Courier Company tie upped
  with codesilver.
  <br />
  5.3 You will become the owner of the goods you have ordered when they have
  been dispatched to you. Once goods have been delivered to you they will be
  held at your own risk and we will not be liable for their loss or destruction.
  <br />
  5.4 We make no promise that materials on our website are appropriate or
  available for use in locations outside the India, and accessing the website
  from territories where its contents are illegal or unlawful is prohibited. If
  you choose to access this site from locations outside the India, you do so on
  your own initiative and are responsible for compliance with local laws.
  <br />
  5.5 If your order is returned to us because the courier or postal service
  could not complete the delivery to you for whatever reason, then you will be
  responsible for the repeat delivery costs.
  <br />
  <br />
  6. Liability:
  <br />
  6.1 If the goods we deliver are not what you ordered or are damaged or
  defective or the delivery is of an incorrect quantity, we shall have no
  liability to you unless you notify us by email of the problem within 7 working
  days of the delivery of the goods.
  <br />
  6.2 If you do not receive goods ordered by you within 7 working days of the
  date on which you ordered them, we shall have no liability to you unless you
  notify us by email or telephone at our contact address of the problem within
  14 days of the date on which you ordered the goods. If you notify us of a
  problem under this condition, our only obligation will be, at your option
  either:
  <br />
  6.2.1 To make good any shortage or non-delivery; or
  <br />
  6.2.2 To replace any goods that are damaged or defective; or
  <br />
  6.2.3 To refund to you the amount paid by you for the goods in question in
  whatever way we choose.
  <br />
  6.3 Save as precluded by law, we will not be liable to you for any indirect or
  consequential loss, damage or expenses (including loss of profits, business or
  goodwill) howsoever arising out of any problem you notify us of under this
  condition and we shall have no liability to pay any money to you by way of
  compensation other than to refund to you the amount paid by you for the goods
  in question under clause 6.2.3 above.
  <br />
  6.4 You must observe and comply with all applicable regulations and
  legislation, including obtaining all necessary customs, import or other
  permits to purchase goods from our site. The importation or exportation of
  certain of our goods to you may be prohibited by certain national laws. We
  make no representation and accept no liability in respect of the export or
  import of the goods you purchase.
  <br />
  6.5 Notwithstanding the foregoing, nothing in these terms and conditions is
  intended to limit any rights you might have as a consumer under applicable
  local law or other statutory rights that may not be excluded nor in any way to
  exclude or limit our liability to you for any death or personal injury
  resulting from our negligence.
  <br />
  6.6 We cannot be held responsible for any delays once the goods have left us
  and are in possession of the courier.
  <br />
  <br />
  7. Notices:
  <br />
  Unless otherwise expressly stated in these terms and conditions, all notices
  from you to us must be in writing and sent by email to our contact address at
  info@codesilver.in
  <br />
  <br />
  8. Invalidity:
  <br />
  If any part of these terms and conditions is unenforceable (including any
  provision in which we exclude our liability to you) the enforceability of any
  other part of these conditions will not be affected.
  <br />
  <br />
  9. Privacy:
  <br />
  You acknowledge and agree to be bound by the terms of our Privacy Policy
  <br />
  <br />
  10. Third Party:
  <br />
  Except for our affiliates, directors, employees or representatives, a person
  who is not a party to this agreement has no right under the Contracts (Rights
  of Third Parties) Act _____ to enforce any term of this agreement but this
  does not affect any right or remedy of a third party that exists or is
  available apart from that Act.
  <br />
  <br />
  11. Governing Law:
  <br />
  Each and every transaction carried out is deemed to be completed within the
  India and therefore shall be governed by and interpreted in accordance with
  Indian Law. The terms of this charter (and any dispute, controversy,
  proceedings or claims of whatever nature in relation to them) shall be
  governed and interpreted in accordance with Indian Law and the Indian Courts
  shall have exclusive jurisdiction in relation thereto.
  <br />
  <br />
  12. Entire Agreement:
  <br />
  12.1 These terms and conditions, together with our current website prices,
  delivery details, contact details and privacy policy, set out the whole of our
  agreement relating to the supply of the goods to you by us. Nothing said by
  any sales person on our behalf should be understood as a variation of these
  terms and conditions or as an authorised representation about the nature or
  quality of any goods offered for sale by us. Save for fraud or fraudulent
  misrepresentation, we shall have no liability for any such representation
  being untrue or misleading. <br />
  12.2 We make no representations or warranties about the accuracy completeness
  or suitability for any purpose of the information and related graphics
  published on our website. From time to time our site may contain technical
  inaccuracies or typographical errors.
  <br />
  <br />
  13. Trade Marks:
  <br />
  Code Silver is the operator of www.codesilver.in. All trademarks, product
  names and company names or logos sited herein are the property of their
  respective owners. No permission is given by us in respect of the use of any
  such brand names, photographs, product names or titles or copyrights and such
  use may constitute an infringement of the owners’ rights.
  <br />
  <br />
  14. Events beyond our Control:
  <br />
  We will not be in breach of this Agreement or otherwise liable for any delay
  in performance if to the extent that any delay or failure is due to
  circumstances beyond its reasonable control including, without limitation,
  strikes, lockouts and other industrial disputes, breakdown of systems or
  network access, flood, fire, explosion or accident.
  <br />
  <br />
  15. Our rights:
  <br />
  We reserve the right to change the Conditions from time to time, and your
  continued use of the Website (or any part thereof) following such change shall
  be deemed to be your acceptance of such change. It is your responsibility to
  check regularly to determine whether the Conditions have been changed. If you
  do not agree to any change to the Conditions then you must immediately stop
  using the Website.
  <br />
  <br />
  16. Refund Policy
  <br />
  If customer paid twice for one transaction, the one transaction amount will be
  refunded via same source within 15 to 20 working days.
  <br />
  <br />
  17. Delivery / Shipping Policy
  <br />
  Product should be delivered to customer by safe &amp; secure - customer should
  intimate about receipt to persons at the time of order so no third person can
  accept the order (identification proof being produced at the time of delivery)
  delivery within 7 to 10 working days.
  <br />
  <br />
  18. Our GST number:
  <br />
  Our GST number is: 08AAVPD6101C1Z1
</p>

          </div>
        </div>
        
      </div>
    </section>
    <FooterDesktop />
  </div>
  )
}

export default Terms