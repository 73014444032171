import React from "react";
import Header from "../../common/Header";
import FooterDesktop from "../../common/FooterDesktop";
import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import s1 from "./imgs/1.jpeg";
import s2 from "./imgs/2.jpeg";
import s3 from "./imgs/3.jpeg";
import s4 from "./imgs/4.jpeg";
import s5 from "./imgs/5.jpeg";
import s6 from "./imgs/6.jpeg";
import s7 from "./imgs/7.jpeg";

const responsive = {
  mobile: {
    breakpoint: { max: 1950, min: 0 },
    items: 1,
  },
};

const Behind = () => {
  return (
    <div>
      <Header />
      <section className="behind-section">
        <div className="container mh-70 mb-30">
          <div className="row">
            <div className="col-sm-6">
              <Carousel
                responsive={responsive}
                showDots={true}
                arrows={false}
                autoPlay={true}
                autoPlaySpeed={2500}
                keyBoardControl={true}
                infinite={true}
                className="trending-slider p-0 mt-100"
              >
                  <img src={s1} alt="" className="img-fluid" />
                  <img src={s2} alt="" className="img-fluid" />
                  <img src={s3} alt="" className="img-fluid" />
                  <img src={s4} alt="" className="img-fluid" />
                  <img src={s5} alt="" className="img-fluid" />
                  <img src={s6} alt="" className="img-fluid" />
                  <img src={s7} alt="" className="img-fluid" />
              </Carousel>
            </div>
            <div className="col-sm-6 mt-80">
              <h1 className="mt-30">We don't follow trends. We create them.</h1>
              <p className="mt-20 text-description font-16">
                Each crook, each dent, each pattern, each smoothening, each
                particle of left over dust has a story to tell.
                <br />
                <br />
                A journey of a thousand steps. A journey of a single crevice. A
                journey of the first dent on smooth, untouched, stunning silver.
                A journey that started with a single karigar in a single room,
                shifted then to a garage, is now a strong base of 112 in a
                workshop. From the inception of every single idea to a
                masterpiece created by hand. Each is different. Because what you
                own, is a piece of a karigar's mind, heart, soul and love. Hands
                that slowly caress the silver that takes shape. Minds that
                create modern designs never seen before. Forging a bond between
                the traditional and the new, one masterpiece at a time.
                <br />
                <br />
                What began as an idea in a small room with one such master
                creator, took 6 years to become Code Silver. The beauty and love
                of our traditional arts, fused with the modern sensibilities of
                our audiences, creating symphonies in molten gray, etching love
                and memories forever. Redefining silver, one memory at a time.
              </p>
            </div>
          </div>
        </div>
      </section>
      <FooterDesktop />
    </div>
  );
};

export default Behind;
