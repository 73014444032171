import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { CatalogContext } from "../../contexts";

const WideRange = () => {
  const { jewelleryCategory, officeCategory, travelCategory } =
    useContext(CatalogContext);
  return (
    <section className="most-wanted dark-section ">
      <div className="container ">
        <div className="row">
          <div className="col-12 text-center">
            <h1>Wide Range of Products</h1>
          </div>
        </div>
        <br />
        <div className="row">
          {jewelleryCategory ? (
            <Link
              to={`/categories/${jewelleryCategory.id}`}
              className="col-sm-4"
            >
              <div className="card card-styled card-styled-dark text-center">
                <img
                  src={jewelleryCategory.original_image}
                  alt=""
                  className="img-fluid"
                />
                <p className="font-14 mb-10 mt-10">
                  <b>{jewelleryCategory.name}</b>
                </p>
              </div>
            </Link>
          ) : null}

          {officeCategory ? (
            <Link to={`/categories/${officeCategory.id}`} className="col-sm-4">
              <div className="card card-styled card-styled-dark text-center">
                <img
                  src={officeCategory.original_image}
                  alt=""
                  className="img-fluid"
                />
                <p className="font-14 mb-10 mt-10">
                  <b>{officeCategory.name}</b>
                </p>
              </div>
            </Link>
          ) : null}
          {travelCategory ? (
            <Link to={`/categories/${travelCategory.id}`} className="col-sm-4">
              <div className="card card-styled card-styled-dark text-center">
                <img
                  src={travelCategory.original_image}
                  alt=""
                  className="img-fluid"
                />
                <p className="font-14 mb-10 mt-10">
                  <b>{travelCategory.name}</b>
                </p>
              </div>
            </Link>
          ) : null}
        </div>
        <br />
        <br />
      </div>
    </section>
  );
};

export default WideRange;
