import React, { useState, useEffect, useContext } from "react";
import Header from "../../common/Header";
import FooterDesktop from "../../common/FooterDesktop";
import { Link, useNavigate } from "react-router-dom";
import httpService from "../../../services/httpService";
import { CatalogContext, SessionContext, UserContext } from "../../../contexts";
import Select from "react-select";

const AddAddress = () => {
  const [newAddress, setNewAddress] = useState({
    firstname: "",
    lastname: "",
    city: "",
    address_1: "",
    address_2: "",
    country_id: "",
    postcode: "",
    zone_id: "",
    //company: "",
    default: false,
  });

  const { sessionId } = useContext(SessionContext);
  const { log } = useContext(UserContext);
  const [countries, setCountries] = useState([]);
  const [zones, setZones] = useState([]);
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const { showToast } = useContext(CatalogContext);

  const navigate = useNavigate();

  useEffect(() => {
    const getAllCountries = async () => {
      try {
        const {
          data: { data: countriesData },
        } = await httpService.get(httpService.apiEndpointShort + "countries", {
          headers: httpService.headers,
        });
        setCountries(
          countriesData.map((country, index) => {
            return {
              value: country.country_id.toString(),
              label: country.name,
            };
          })
        );
      } catch (error) {
        console.log(error);
      }
    };

    if (log === 0) {
      navigate("/login");
    }
    if (sessionId && log === 1) {
      getAllCountries();
    }
  }, [sessionId, log]);

  const handleCountryChange = async (e, obj, setObj) => {
    const form = { ...obj };
    form.country_id = e.value;
    form.zone_id = null;
    try {
      const {
        data: {
          data: { zone: countriesZones },
        },
      } = await httpService.get(
        httpService.apiEndpointShort + "countries/" + e.value,
        { headers: httpService.headers }
      );
      console.log(countriesZones);
      setZones(
        countriesZones.map((zone, index) => {
          return { value: zone.zone_id, label: zone.name };
        })
      );
      setObj(form);
    } catch (error) {
      console.log("zone select error", error);
    }
  };

  const handleZoneChange = async (e, obj, setObj) => {
    const form = { ...obj };
    console.log(e);
    form.zone_id = e.value;
    setObj(form);
  };

  const handleChange = (e, obj, setObj) => {
    const form = { ...obj };
    form[e.target.name] = e.target.value;
    setObj(form);
  };

  const addNewAddress = async () => {
    setButtonsDisabled(true);
    const keys = Object.keys(newAddress);
    let check = true;
    for (let i = 0; i < keys.length; i++) {
      if (newAddress[keys[i]] === "" || newAddress[keys[i]] === null) {
        if (keys[i] === "address_2") {
          continue;
        }
        showToast("error", `${keys[i]} is required!`);

        check = false;
        setButtonsDisabled(false);
        return false;
      }
    }
    if (check) {
      try {
        let response = await httpService.post(
          httpService.apiEndpointShort + "account/address",
          newAddress,
          { headers: { ...httpService.headers, "X-Oc-Session": sessionId } }
        );
        response = response.data;
        console.log("create user response-", response);
        if (
          response &&
          "success" in response &&
          response.success === 1 &&
          "error" in response &&
          response.error.length === 0
        ) {
          console.log("new address added");
          showToast("success", "New Address Added successfully!");

          setZones([]);
          setNewAddress({
            firstname: "",
            lastname: "",
            city: "",
            address_1: "",
            address_2: "",
            country_id: "",
            postcode: "",
            zone_id: "",
            company: "",
          });
          navigate(-1);
        } else if (
          response &&
          "success" in response &&
          response.success !== 1 &&
          "error" in response &&
          response.error.length !== 0
        ) {
          console.log(
            "add new address unsucessful due to errors!",
            response.error[0]
          );
          showToast("error", response.error[0]);

          setButtonsDisabled(false);
        } else {
          console.log("add address unsucessful due to unknown!");
          showToast("error", "Something went wrong!");

          setButtonsDisabled(false);
        }
      } catch (error) {
        console.log("error", error);
        setButtonsDisabled(false);
        const expectedError =
          error.response &&
          error.response.status >= 400 &&
          error.response.status < 500;

        if (expectedError) {
          showToast("error", error.response.data.error[0]);
          console.log("address add failed!", error.response);
        } else {
          showToast("error", "Something went wrong!");

          console.log("address add failed!", error);
        }
        setButtonsDisabled(false);
      }
    }
  };
  return (
    <>
      {log === 1 ? (
        <div>
          <Header />

          <div className="section mh-70">
            <div className="container">
              <div className="row text-center">
                <div className="col-12">
                  <h1 className="mt-120">Add Address</h1>
                  <Link
                    to="../addressBook"
                    className="back_btn fl text-description"
                  >
                    <i className="bi bi-arrow-left-circle-fill"></i> Go Back
                  </Link>
                </div>
              </div>
              <div className="row">
                <div className="col-12 offset-0 col-sm-8 offset-sm-2">
                  <div className="row">
                    <div className="col-sm-6">
                      <fieldset id="account">
                        <div className="form-group" style={{ display: "none" }}>
                          <label className="control-label">
                            Customer Group
                          </label>
                          <div className="radio">
                            <label>
                              <input
                                type="radio"
                                name="customer_group_id"
                                defaultValue={1}
                                defaultChecked="checked"
                              />
                              Default
                            </label>
                          </div>
                        </div>
                        <div className="form-group required">
                          <input
                            type="text"
                            name="firstname"
                            onChange={(e) =>
                              handleChange(e, newAddress, setNewAddress)
                            }
                            value={newAddress.firstname}
                            placeholder="First Name"
                            id="input-payment-firstname"
                            className="form-control"
                          />
                        </div>
                        <div className="form-group required">
                          <input
                            type="text"
                            name="lastname"
                            onChange={(e) =>
                              handleChange(e, newAddress, setNewAddress)
                            }
                            value={newAddress.lastname}
                            placeholder="Last Name"
                            id="input-payment-lastname"
                            className="form-control"
                          />
                        </div>
                        {/* <div className="form-group required">
                          <input
                            type="email"
                            name="email"
                            onChange={(e) =>
                              handleChange(e, newAddress, setNewAddress)
                            }
                            value={newAddress.email}
                            placeholder="E-Mail"
                            id="input-payment-email"
                            className="form-control"
                          />
                        </div> */}
                        <div className="form-group required">
                          <select
                            className="form-control"
                            style={{ width: "35%", float: "left" }}
                            name="countryCode"
                            id="c_code"
                          >
                            <optgroup>
                              <option data-countrycode="ZM" value={260}>
                                Zambia (+260)
                              </option>
                              <option data-countrycode="ZW" value={263}>
                                Zimbabwe (+263)
                              </option>
                            </optgroup>
                          </select>
                          <input
                            style={{ width: "60%", float: "right" }}
                            type="number"
                            name="telephone1"
                            onChange={(e) =>
                              handleChange(e, newAddress, setNewAddress)
                            }
                            value={newAddress.telephone}
                            placeholder="Mobile Number"
                            id="input-payment-telephone"
                            className="form-control"
                          />
                        </div>
                        <input
                          style={{ width: "69%", float: "right" }}
                          type="hidden"
                          name="telephone"
                          defaultValue=""
                          placeholder="Mobile Number"
                          id="input-payment-telephone1"
                        />
                        <div style={{ display: "none" }} className="form-group">
                          <label
                            className="control-label"
                            htmlFor="input-payment-fax"
                          >
                            Fax
                          </label>
                          <input
                            type="text"
                            name="fax"
                            defaultValue=""
                            placeholder=""
                            id="input-payment-fax"
                            className="form-control"
                          />
                        </div>
                      </fieldset>
                    </div>
                    <div className="col-sm-6">
                      <fieldset id="address">
                        <div style={{ display: "none" }} className="form-group">
                          <label
                            className="control-label"
                            htmlFor="input-payment-company"
                          >
                            Company
                          </label>
                          <input
                            type="text"
                            name="company"
                            defaultValue=""
                            placeholder="Company"
                            id="input-payment-company"
                            className="form-control"
                          />
                        </div>
                        <div id="address_col" className="form-group required">
                          <input
                            type="text"
                            name="address_1"
                            onChange={(e) =>
                              handleChange(e, newAddress, setNewAddress)
                            }
                            value={newAddress.address_1}
                            placeholder="Address"
                            id="input-payment-address-1"
                            className="form-control"
                          />
                        </div>
                        <div style={{ display: "none" }} className="form-group">
                          <label
                            className="control-label"
                            htmlFor="input-payment-address-2"
                          >
                            Address 2
                          </label>
                          <input
                            type="text"
                            name="address_2"
                            onChange={(e) =>
                              handleChange(e, newAddress, setNewAddress)
                            }
                            value={newAddress.address_2}
                            placeholder="Address 2"
                            id="input-payment-address-2"
                            className="form-control"
                          />
                        </div>
                        <div className="form-group required">
                          <div className="row">
                            <div
                              style={{ paddingLeft: 0 }}
                              className="col-sm-8 p-0"
                            >
                              <input
                                type="text"
                                name="city"
                                onChange={(e) =>
                                  handleChange(e, newAddress, setNewAddress)
                                }
                                value={newAddress.city}
                                placeholder="City"
                                id="input-payment-city"
                                className="form-control"
                              />
                            </div>
                            <div id="pin_box" className="col-sm-4 p-0">
                              <input
                                type="text"
                                name="postcode"
                                onChange={(e) =>
                                  handleChange(e, newAddress, setNewAddress)
                                }
                                value={newAddress.postcode}
                                placeholder="PIN/ZIP code"
                                id="input-payment-postcode"
                                className="form-control"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-group required">
                          <Select
                            onChange={(e) =>
                              handleCountryChange(e, newAddress, setNewAddress)
                            }
                            options={countries}
                            placeholder="Select Country"
                            name="country_id"
                            value={countries.filter(function (option) {
                              return option.value === newAddress.country_id;
                            })}
                          />

                          <Select
                            onChange={(e) =>
                              handleZoneChange(e, newAddress, setNewAddress)
                            }
                            options={zones}
                            placeholder={
                              zones.length === 0
                                ? "Please select country first"
                                : "Select State"
                            }
                            name="zone_id"
                            value={zones.filter(function (option) {
                              return option.value === newAddress.zone_id;
                            })}
                          />
                        </div>
                        <div
                          className="form-check"
                          style={{ margin: "15px 0" }}
                        >
                          <input
                            type="checkbox"
                            onChange={() => {
                              setNewAddress({
                                ...newAddress,
                                default: !newAddress.default,
                              });
                            }}
                            name="default"
                            checked={newAddress.default}
                            value={newAddress.default}
                            className="form-check-input"
                          />
                          <label className="form-check-label">
                            Make this default address
                          </label>
                        </div>
                      </fieldset>
                    </div>
                  </div>

                  <br />
                  <div className="buttons">
                    <div className="text-center">
                      <div>
                        <button
                          onClick={(e) => addNewAddress(e)}
                          disabled={buttonsDisabled}
                          id="button-guest"
                          data-loading-text="Loading..."
                          className="btn btn-primary btn-gold w-100"
                        >
                          {buttonsDisabled ? "Adding..." : "Add Address"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
          <FooterDesktop page="/profile" />
        </div>
      ) : null}
    </>
  );
};

export default AddAddress;
