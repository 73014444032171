import React from 'react'
import Header from "../../common/Header";
import FooterDesktop from "../../common/FooterDesktop";
import { Link } from "react-router-dom";
import ProductCardWishlist from './ProductCardWishlist';

const Wishlist = () => {
  return (
    <div>
        <Header />
      <div className="section mh-70">
       <div className="container">
        <div className="row">
          <div className="col-12 text-center">
        
          <h1 className="mt-120">Wishlist</h1>
          <Link to="../account" className="back_btn fl text-description"> <i className="bi bi-arrow-left-circle-fill"></i> Go Back</Link>
        
          </div>
        </div>
          <div className="row">
             <ProductCardWishlist />
             <ProductCardWishlist />
             <ProductCardWishlist />
          </div>
       </div>
      </div><br /><br />
      <FooterDesktop page="/profile" />
    </div>
  )
}

export default Wishlist