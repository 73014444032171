import React, { useState, useEffect, useContext } from "react";
import Header from "../../common/Header";
import FooterDesktop from "../../common/FooterDesktop";
import { Link, useNavigate } from "react-router-dom";
import AddressCard from "./AddressCard";
import httpService from "../../../services/httpService";
import { CatalogContext, SessionContext, UserContext } from "../../../contexts";
import Loader from "../../common/Loader";
import Loading from "../../common/Loading";

const AddressBook = () => {
  const [addresses, setAddresses] = useState();
  const { sessionId } = useContext(SessionContext);
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const { log } = useContext(UserContext);
  const navigate = useNavigate();
  const { showToast } = useContext(CatalogContext);

  useEffect(() => {
    if (log === 0) {
      navigate("/");
    }
    const getAllAddresses = async () => {
      try {
        const {
          data: { data: allAddresses },
        } = await httpService.get(
          httpService.apiEndpoint + "rest/account/address",
          { headers: { ...httpService.headers, "X-Oc-Session": sessionId } }
        );
        console.log(allAddresses);
        if (allAddresses) {
          setAddresses(allAddresses.addresses);
        } else {
          setAddresses([]);
        }
      } catch (error) {
        console.log(error);
        if (error.response) {
          showToast("error", error.response.data.error[0]);
        } else {
          showToast("error", "Unable to fetch your addresses!");
        }
      }
    };
    if (sessionId && log === 1) {
      getAllAddresses();
    }
  }, [sessionId, log]);

  const deleteAddress = async (address_id) => {
    setButtonsDisabled(true);

    try {
      let response = await httpService.delete(
        httpService.apiEndpoint + "rest/account/address&id=" + address_id,
        { headers: { ...httpService.headers, "X-Oc-Session": sessionId } }
      );
      response = response.data;
      console.log("edit address-", response);
      if (
        response &&
        "success" in response &&
        response.success === 1 &&
        "error" in response &&
        response.error.length === 0
      ) {
        const getAllAddresses = async () => {
          try {
            const {
              data: { data: allAddresses },
            } = await httpService.get(
              httpService.apiEndpoint + "rest/account/address",
              { headers: { ...httpService.headers, "X-Oc-Session": sessionId } }
            );
            console.log(allAddresses);
            if (allAddresses && allAddresses.addresses) {
              setAddresses(allAddresses.addresses);
            } else {
              setAddresses([]);
            }
          } catch (error) {
            console.log(error);
            if (error.response) {
              showToast("error", error.response.data.error[0]);
            } else {
              showToast("error", "Unable to fetch your addresses!");
            }
          }
        };

        await getAllAddresses();

        console.log("address deleted");
        showToast("success", "Address deleted successfully!");

        setButtonsDisabled(false);
      } else if (
        response &&
        "success" in response &&
        response.success !== 1 &&
        "error" in response &&
        response.error.length !== 0
      ) {
        console.log(
          "delete address unsucessful due to errors!",
          response.error[0]
        );
        showToast("error", response.error[0]);

        setButtonsDisabled(false);
      } else {
        console.log("delete address unsucessful due to unknown!");
        showToast("error", "Something went wrong!");

        setButtonsDisabled(false);
      }
    } catch (error) {
      console.log("error", error);
      setButtonsDisabled(false);
      const expectedError =
        error.response &&
        error.response.status >= 400 &&
        error.response.status < 500;

      if (expectedError) {
        showToast("error", error.response.data.error[0]);

        console.log("delete address failed!", error.response);
      } else {
        showToast("error", "Something went wrong!");

        console.log("delete address failed!", error);
      }
    }
  };
  return (
    <>
      <Header />
      {log === 1 && addresses ? (
        <div>
          <div className="section mh-70">
            <div className="container">
              <div className="row">
                <div className="col-12 text-center">
                  <h1 className="mt-120">Address Book</h1>
                  <Link
                    to="../account"
                    className="back_btn fl text-description"
                  >
                    {" "}
                    <i className="bi bi-arrow-left-circle-fill"></i> Go Back
                  </Link>
                </div>
              </div>
              <br />
              <div className="row">
                {addresses ? (
                  addresses.map((address) => {
                    return (
                      <AddressCard
                        key={address.address_id}
                        address={address}
                        deleteAddress={deleteAddress}
                      />
                    );
                  })
                ) : (
                  <Loader />
                )}
              </div>
              <br />
              <div className="row text-center">
                <Link to="../addAddress">
                  <button className="btn btn-primary btn-gold">
                    {" "}
                    <i className="bi bi-plus-circle"></i>&nbsp; Add New Address
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <br />
          <br />
        </div>
      ) : (
        <Loading />
      )}
      <FooterDesktop page="/profile" />
    </>
  );
};

export default AddressBook;
