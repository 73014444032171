import React from "react";
import QuantityInputButtons from "../../quantityInputButtons/QuantityInputButtons";

const ProductInfo = ({
  productDetails,
  productPrice,
  quantity,
  handleIncrement,
  handleDecrement,
  handleCustomQuantityInput,
  buttonsDisabled,
  handleAddToCart,
  handleOtherOptionChange,
  productForm,
}) => {
  return (
    <>
      {/* {console.log(productDetails)} */}
      {productDetails && productForm ? (
        <>
          <section className="product-info">
            <div className="row">
              <div className="col-12">
                <p className="product-name mb-0 font-24 golden-color mt-10">
                  {productDetails.name}
                </p>
                <button className="btn btn-counter wishlist-btn">
                  <i className="bi bi-heart font-16 text-gold"></i>
                </button>
                <p className="product-price mb-0 font-18 text-white mt-5px">
                  {productDetails.price_formated.slice(0, 1) +
                    productPrice
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </p>

                <p className="mb-0 mt-10">Quantity</p>

                <QuantityInputButtons
                  quantity={quantity}
                  handleIncrement={handleIncrement}
                  handleDecrement={handleDecrement}
                  handleCustomQuantityInput={handleCustomQuantityInput}
                ></QuantityInputButtons>
              </div>
            </div>
            <br />
            {productDetails.options.map((option) => {
              if (option.type == "input" || option.type == "textarea") {
                return (
                  <>
                    <div className="row">
                      <div className="col-12">
                        <input
                          data-type={option.type}
                          data-name={option.name}
                          name={option.product_option_id}
                          type="text"
                          placeholder={option.name}
                          className="form-control golden-input"
                          value={productForm.option[option.product_option_id]}
                          onChange={handleOtherOptionChange}
                        />
                      </div>
                    </div>
                    <br />
                  </>
                );
              }
            })}

            {productDetails.options.map((option) => {
              if (option.type === "select") {
                return (
                  <div className="row">
                    <div className="col-12">
                      <select
                        name={option.product_option_id}
                        onChange={handleOtherOptionChange}
                        value={productForm.option[option.product_option_id]}
                        className="form-control golden-input"
                      >
                        <option onChange={handleOtherOptionChange} value="">
                          Select {option.name}
                        </option>
                        {option.option_value.map((ov) => {
                          return (
                            <option
                              data-product-option-details={JSON.stringify(ov)}
                              value={ov.product_option_value_id}
                            >
                              {ov.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                );
              }
            })}

            <div className="row">
              <div className="col-6 mt-20">
                <button
                  className="btn btn-primary btn-gold w-100 "
                  disabled={buttonsDisabled}
                  onClick={() => handleAddToCart()}
                >
                  <i className="bi bi-cart3"></i>{" "}
                  {buttonsDisabled ? "Added to Cart" : "Add to Cart"}
                </button>
              </div>
              <div className="col-6 mt-20">
                <button className="btn btn-primary btn-gold w-100 btn-outline">
                  <i className="bi bi-lightning"></i> BUY NOW
                </button>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div
                  className="accordion accordion-m border-0"
                  id="accordion-group-5"
                >
                  <div className="accordion-item">
                    <button
                      className="accordion-button px-0 ps-1 collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#accordion5-1"
                      aria-expanded="true"
                    >
                      <span className="font-600 font-13 text-description">
                        Product Description
                      </span>
                      <i className="bi bi-plus font-20 text-description" />
                    </button>
                    <div
                      id="accordion5-1"
                      className="accordion-collapse"
                      data-bs-parent="#accordion-group-5"
                    >
                      <p className="mb-0 pb-3 text-description">
                        {productDetails.description.replace(/<[^>]*>?/gm, "")}
                      </p>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <button
                      className="accordion-button px-0 ps-1 collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#accordion5-2"
                    >
                      <span className="font-600 font-13 text-description">
                        Product Care
                      </span>
                      <i className="bi bi-plus font-20" />
                    </button>
                    <div
                      id="accordion5-2"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordion-group-5"
                    >
                      <p className="mb-0 pb-3 text-description">
                        Do not scrub any of the products.
                        <br />
                        To clean this, you only need to wipe it with a damp
                        cloth
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : null}
    </>
  );
};

export default ProductInfo;
