import React from "react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import {  SessionContext } from "../../contexts";
function hideSidebar() {
  document.getElementById("sidebar-bg").style.display = "none";
  document.getElementById("side-bar").style.left = "-270px";
}

const SideBar = () => {
  const { currency, setCurrency } = useContext(SessionContext);
  return (
    <>
      <div id="sidebar-bg" onClick={() => hideSidebar()}></div>
      <div id="side-bar">
        <i
          onClick={() => hideSidebar()}
          className="bi bi-x font-22 text-gold"
          id="close-sidebar"
        ></i>
        <nav className="mobile_nav">
          <Link to="../categories/62" className="golden-color">
            Barewar
          </Link>
          <hr />
          <Link to="../categories/59" className="golden-color">
            Home & Decor
          </Link>
          <hr />
          <Link to="../categories/60" className="golden-color">
            Travel
          </Link>
          <hr />
          <Link to="../categories/63" className="golden-color">
            Jewellery
          </Link>
          <hr />
          <Link to="../categories/61" className="golden-color">
            Office
          </Link>
        </nav>
           &nbsp;&nbsp;&nbsp; <select
              value={currency}
              onChange={(e) => {
                setCurrency(e.target.value);
              }}
              className="currency_drop "
            >
              <option value="INR">INR</option>
              <option value="USD">USD</option>
            </select>
      </div>
    </>
  );
};

export default SideBar;
