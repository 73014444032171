import React from "react";
import Header from "../common/Header";
import FooterDesktop from "../common/FooterDesktop";

const Contact = () => {
  return (
    <div>
      <Header />
      <section className="mh-70">
        <div className="container mb-30">
          <div className="row">
            <div className="col-12 text-center">
              <h1 className="mt-100">Contact Us</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-10 offset-sm-1 col-12 offset-0 mt-30">
              <div className="row">
                <div className="col-sm-6 col-12">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3557.728539453335!2d75.80056921504497!3d26.91210908312781!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396db403ba23aeab%3A0x7bf472b384e510f4!2sCode+Silver!5e0!3m2!1sen!2sin!4v1532490190206" width="600" height="450" frameborder="0" style={{border:0,width:'100%'}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>

                </div>
                <div className="col-sm-6 col-12">
                  <p className="text-description font-16">
                    <font className="font-20 text-gold" >CODE SILVER WILD</font>
                    <br />
                    D-31, Subhash Marg, C-Scheme Jaipur, 302001
                    <br />
                    <br />
                  
                    <a tel="+919799332768" className="text-description">
                    <i className="bi bi-phone"></i>   +91-97993 32768
                    </a>
                  
                    <br />
                    <a tel="+919950039573" className="text-description">
                    <i className="bi bi-phone"></i>  +91-99500 39573
                    </a>
                   
                   
                    <br />
                    <a tel="+919950093777" className="text-description">
                      <i className="bi bi-phone"></i>  +91-99500 93777
                    </a>
                  
                    <br />
                   <a href="+919950090777" className="text-description">
                   <i className="bi bi-phone"></i> +91-99500 90777
                   </a>
                   
                   
                    <br />
                    <br />
                    <a href="mailto:info@codesilver.in" className="text-gold">
                    <i className="bi bi-envelope"></i>  info@codesilver.in
                    </a>
                    
                    
                    <br />
                    <br />
                    If you are a hotel, resort, travel agent or a wild life
                    photographer looking for collaboration
                    <br />
                    <br />
                    <a href="mailto:collaboration@codesilver.in" className="text-gold">
                    <i className="bi bi-envelope"></i> collaboration@codesilver.in
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterDesktop />
    </div>
  );
};

export default Contact;
