import React from "react";
import MobileCartItem from "./MobileCartItem";

const MobileCart = ({ cart, updateQuantity, deleteFromCart }) => {
  return (
    <div>
      {cart.map((cartItem, index) => {
        return (
          <MobileCartItem
            key={
              cartItem.product_id +
              cartItem.price +
              cartItem.quantity +
              cartItem.total +
              index
            }
            cartItem={cartItem}
            updateQuantity={updateQuantity}
            deleteFromCart={deleteFromCart}
          />
        );
      })}
    </div>
  );
};

export default MobileCart;
