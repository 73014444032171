import { createContext, useState, useEffect, useContext } from "react";
import httpService from "../services/httpService"
import { Buffer } from "buffer";

import { toast } from "react-toastify";
import {SessionContext} from "./SessionContext";
import { CatalogContext } from "./CatalogContext";

const UserContext = createContext();

const UserProvider = (props) => {
  const [user, setUser] = useState(localStorage.getItem("user"));
  const [userDetails, setUserDetails] = useState(
    JSON.parse(localStorage.getItem("userDetails"))
  );
const { showToast } = useContext(CatalogContext);

  const { sessionId } = useContext(SessionContext);
  const [log, setLog] = useState(-1);
  useEffect(() => {
    const fetchData = async()=>{
      try {
        const loginDetails = Buffer.from(user, "base64").toString("ascii");
        console.log(loginDetails);
        const { data: response } = await httpService.post(
          httpService.apiEndpointShort + "login",
          loginDetails,
          { headers: { ...httpService.headers, "X-Oc-Session": sessionId } }
        );
        console.log("auto log resp", response);
        setLog(1);
      } catch (error) {
        console.log("auto log error", error);
        localStorage.removeItem("user");
        localStorage.removeItem("userDetails");
        setUser(undefined);
        setUserDetails(undefined);
        setLog(0);
      }
    }
    if(!user){
      setLog(0);
    }
    if (user && sessionId) {
      fetchData();
    }
  }, [sessionId]);

  const signup = async (registration) => {
    try {
      const { data: response } = await httpService.post(
        httpService.apiEndpointShort + "register",
        registration,
        { headers: { ...httpService.headers, "X-Oc-Session": sessionId } }
      );
      console.log(response);
      if (response && "success" in response && response.success === 1) {
        localStorage.setItem(
          "user",
          Buffer.from(
            JSON.stringify({
              email: registration.email,
              password: registration.password,
            })
          ).toString("base64")
        );

        localStorage.setItem(
          "userDetails",
          JSON.stringify({
            firstname: response.data.firstname,
            lastname: response.data.lastname,
            email: response.data.email,
            telephone: response.data.telephone,
          })
        );

        setUser(localStorage.getItem("user"));
        setUserDetails(JSON.parse(localStorage.getItem("userDetails")));
        setLog(1);
        return response;
      } else if (response && "success" in response && response.success !== 1) {
        console.log(response);
        return response;
      }
    } catch (error) {
      console.log("error", error);
      if (error.response) {
        return error.response.data;
      } else {
        return { success: 0, error: ["Something went wrong"] };
      }
    }
  };

  const login = async (loginForm) => {
    try {
      const { data: response } = await httpService.post(
        httpService.apiEndpointShort + "login",
        loginForm,
        { headers: { ...httpService.headers, "X-Oc-Session": sessionId } }
      );
      console.log(response);
      if (response && "success" in response && response.success === 1) {
        localStorage.setItem(
          "user",
          Buffer.from(
            JSON.stringify({
              email: loginForm.email,
              password: loginForm.password,
            })
          ).toString("base64")
        );

        localStorage.setItem(
          "userDetails",
          JSON.stringify({
            firstname: response.data.firstname,
            lastname: response.data.lastname,
            email: response.data.email,
            telephone: response.data.telephone,
          })
        );

        setUser(localStorage.getItem("user"));
        setUserDetails(JSON.parse(localStorage.getItem("userDetails")));
        setLog(1);
        return response;
      } else if (response && "success" in response && response.success !== 1) {
        console.log(response);
        return response;
      }
    } catch (error) {
      console.log("error", error);
      if (error.response) {
        return error.response.data;
      } else {
        return { success: 0, error: ["Something went wrong"] };
      }
    }
  };

  const logout = async () => {
    try {
      await httpService.post(
        httpService.apiEndpointShort + "logout",
        {},
        { headers: { ...httpService.headers, "X-Oc-Session": sessionId } }
      );
      localStorage.removeItem("user");
      localStorage.removeItem("userDetails");
      setUser(undefined);
      setUserDetails(undefined);
      setLog(0);
    } catch (error) {
      if (error.response) {
        showToast("error", error.response.data.error[0]);
      } else {
        showToast("error", "Something went wrong!");
      }
    }
  };

  const updateProfile = async (userForm) => {
    try {
      const { data: response } = await httpService.put(
        httpService.apiEndpoint + "rest/account/account",
        userForm,
        { headers: { ...httpService.headers, "X-Oc-Session": sessionId } }
      );
      console.log(response);
      if (response && "success" in response && response.success === 1) {


        console.log("update response",response);
        
        

        localStorage.setItem(
          "user",
          Buffer.from(
            JSON.stringify({
              email: userForm.email,
              password: JSON.parse(Buffer.from(user, "base64").toString("ascii")).password,
            })
          ).toString("base64")
        );

        localStorage.setItem(
          "userDetails",
          JSON.stringify({
            firstname: userForm.firstname,
            lastname: userForm.lastname,
            email: userForm.email,
            telephone: userDetails.telephone,
          })
        );

        setUserDetails(JSON.parse(localStorage.getItem("userDetails")));
        setUser(localStorage.getItem("user"));

        return response;
      } else if (response && "success" in response && response.success !== 1) {
        console.log(response);
        return response;
      }
    } catch (error) {
      console.log("error", error);
      if (error.response) {
        return error.response.data;
      } else {
        return { success: 0, error: ["Something went wrong"] };
      }
    }
  };

  const updatePassword = async (userForm) => {
    try {
      const { data: response } = await httpService.put(
        httpService.apiEndpoint + "rest/account/password",
        userForm,
        { headers: { ...httpService.headers, "X-Oc-Session": sessionId } }
      );
      console.log(response);
      if (response && "success" in response && response.success === 1) {


        console.log("update response",response);
        
        

        localStorage.setItem(
          "user",
          Buffer.from(
            JSON.stringify({
              email: userDetails.email,
              password: userForm.password,
            })
          ).toString("base64")
        );

        setUser(localStorage.getItem("user"));

        return response;
      } else if (response && "success" in response && response.success !== 1) {
        console.log(response);
        return response;
      }
    } catch (error) {
      console.log("error", error);
      if (error.response) {
        return error.response.data;
      } else {
        return { success: 0, error: ["Something went wrong"] };
      }
    }
  };

  const getWishlist = async ()=>{
    try{
      const {data:fetchedWishlist} = httpService.get(httpService.apiEndpoint + 'rest/wishlist/wishlist', {headers: {...httpService.headers, "X-Oc-Session": sessionId}})
      console.log(fetchedWishlist);
    }catch(error){
      console.log(error);
    }
    
  }

  return (
    <UserContext.Provider
      value={{ user, login, signup, logout, userDetails, log, updateProfile, updatePassword }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
