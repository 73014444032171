import React from "react";
import { Link } from "react-router-dom";
import Header from "../../common/Header";
import FooterDesktop from "../../common/FooterDesktop";

const PaymentFailed = () => {
  return (
    <div>
      <Header />

      <section className="mh-70">
        <div className="container mt-100">
          <div className="row text-center ">
            <h1 className="mtb-20 font-26">Payment Failed!</h1>
            <p className="font-20">
              We could not process your order at this time. <br />
              Please try again later.
            </p>
            <Link to="/" className="btn btn-primary btn-gold ">
              Continue Shopping
            </Link>
          </div>
        </div>
      </section>
      <FooterDesktop />
    </div>
  );
};

export default PaymentFailed;
