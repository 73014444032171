import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { CatalogContext } from "../../../contexts";

const responsive = {
  mobile: {
    breakpoint: { max: 1950, min: 0 },
    items: 1,
  },
};

const SliderTop = () => {
  const { topBannersMobile: banners } = useContext(CatalogContext) || [];

  return (
    <div className="row p-0">
      <div className="col-12 mt-80 p-0">
        <Carousel
          responsive={responsive}
          showDots={true}
          arrows={false}
          className="trending-slider p-0"
        >
          {banners.map((banner, index) => {
            return (
              <Link key={index} to={banner.link} className="mySlider-slide">
                <img src={banner.image} alt="" className="img-fluid" />
              </Link>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
};

export default SliderTop;
