import React, { useState, useEffect, useContext } from "react";
import Header from "../../common/Header";
import FooterDesktop from "../../common/FooterDesktop";
import ProductSlider from "./ProductSlider";
import ProductInfo from "./ProductInfo";
import YouMayLike from "./YouMayLike";

import httpService from "../../../services/httpService";
import { useParams } from "react-router-dom";
import { CartContext } from "../../../contexts/CartContext";
import Loading from "../../common/Loading";
import Loader from "../../common/Loader";
import Curtain from "../../common/Curtain";
import { CatalogContext, SessionContext } from "../../../contexts";

const Product = () => {
  const { currency } = useContext(SessionContext);
  const [isLoaded, setLoaded] = useState(0);
  const [productDetails, setProductDetails] = useState();
  const [productForm, setProductForm] = useState();
  const [productPrice, setProductPrice] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [relatedProducts, setRelatedProducts] = useState();
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const { addToCart } = useContext(CartContext);
  const { showToast } = useContext(CatalogContext);

  const params = useParams();

  const handleAddToCart = () => {
    if (quantity < productDetails.minimum) {
      showToast("error", "Minimum quantity is " + productDetails.minimum);
      return;
    }

    for (let option of productDetails.options) {
      if (
        Number(option.required) === 1 &&
        (!(String(option.product_option_id) in productForm.option) ||
          productForm.option[option.product_option_id] == "")
      ) {
        showToast("error", "Please choose a " + option.name);
        return;
      }
    }
    setButtonsDisabled(true);
    const updatedProductForm = { ...productForm };
    updatedProductForm.total = productForm.price * quantity;
    updatedProductForm.total_excluding_tax =
      productForm.price_excluding_tax * quantity;
    updatedProductForm.quantity = quantity;
    addToCart(updatedProductForm);
    setProductForm(updatedProductForm);

    setTimeout(() => {
      setButtonsDisabled(false);
    }, 3000);
  };

  const displayAllStates = () => {
    console.log(productForm);
  };

  const handleOptionChange = (e) => {
    console.log("change");
    const newOption = JSON.parse(
      e.target.getAttribute("data-product-option-details")
    );
    console.log(newOption);
    let productFormOptionDetails = [...productForm.option_details];
    productFormOptionDetails = productFormOptionDetails.map((option, index) => {
      if (option.product_option_id === newOption.product_option_id) {
        const updateOption = { ...option };
        updateOption.product_option_value_id =
          newOption.product_option_value_id;
        updateOption.value = newOption.name;
        updateOption.option_value_id = newOption.option_value_id;
        updateOption.price = newOption.price;
        updateOption.price_excluding_tax = newOption.price_excluding_tax;
        return updateOption;
      }

      return { ...option };
    });

    let productFormOption = { ...productForm.option };
    productFormOption[newOption.product_option_id] =
      newOption.product_option_value_id;

    if (Number(newOption.parent_id) === 0) {
      console.log("p1");
      productDetails.options.map((option) => {
        console.log("p3");
        if (Number(option.parent_id) !== 0) {
          console.log("P2");
          delete productFormOption[String(option.product_option_id)];
          console.log(productFormOption);
        }
      });

      productFormOptionDetails = productFormOptionDetails.filter((option) => {
        return Number(option.parent_id) === 0;
      });

      productDetails.options.map((opt) => {
        if (Number(opt.parent_id) !== 0) {
          let put = false;
          opt.option_value.map((ov) => {
            if (put === false) {
              if (
                ov.parent_option_values.split(", ").includes(
                  String(
                    productFormOptionDetails.filter((op) => {
                      return Number(op.option_id) === Number(opt.parent_id);
                    })[0].option_value_id
                  )
                )
              ) {
                console.log("in here");
                put = true;

                productFormOptionDetails.push({
                  product_option_id: opt.product_option_id,
                  product_option_value_id: ov.product_option_value_id,
                  option_id: opt.option_id,
                  option_value_id: ov.option_value_id,
                  name: opt.name,
                  value: ov.name,
                  type: opt.type,
                  price: ov.price,
                  price_excluding_tax: ov.price_excluding_tax,
                  parent_id: opt.parent_id,
                });

                productFormOption[opt.product_option_id] =
                  ov.product_option_value_id;
              }
            }
          });
        }
      });
    }

    console.log(3, productFormOptionDetails, productFormOption);

    let updatedPrice = productDetails.price;
    let updatedPriceExcludingTax = productDetails.price_excluding_tax;
    productFormOptionDetails.map((opt) => {
      updatedPrice = updatedPrice + opt.price;
      updatedPriceExcludingTax =
        updatedPriceExcludingTax + opt.price_excluding_tax;
    });

    setProductPrice(updatedPrice);
    const newProductForm = { ...productForm };
    newProductForm.option = productFormOption;
    newProductForm.option_details = [...productFormOptionDetails];
    newProductForm.price = updatedPrice;
    newProductForm.price_excluding_tax = updatedPriceExcludingTax;
    newProductForm.total = updatedPrice * quantity;
    newProductForm.total_excluding_tax = updatedPriceExcludingTax * quantity;
    setProductForm(newProductForm);
  };
  const handleOtherOptionChange = (e) => {
    console.log(e.target.getAttribute("name"));

    let opts = productDetails.options.find((opt) => {
      return opt.product_option_id == e.target.getAttribute("name");
    });
    if (
      e.target.getAttribute("data-type") == "input" ||
      e.target.getAttribute("data-type") == "textarea"
    ) {
      console.log("ip ta");
      let pf = { ...productForm };
      let ops = { ...pf.option };
      ops[e.target.getAttribute("name")] = e.target.value;
      let ods = [...pf.option_details];
      ods = ods.filter((od) => {
        return od.product_option_id != e.target.getAttribute("name");
      });
      ods.push({
        product_option_id: e.target.getAttribute("name"),
        name: e.target.getAttribute("data-name"),
        value: e.target.value,
      });
      pf.option = ops;
      pf.option_details = ods;
      setProductForm(pf);
      return;
    }
    console.log("change!");
    let ppet = productForm.price_excluding_tax;
    let ppit = productForm.price;
    let oldvalue = productForm.option[e.target.getAttribute("name")];
    console.log(oldvalue);

    console.log(opts);

    let optsov = opts.option_value.find((ovv) => {
      return ovv.product_option_value_id == oldvalue;
    });
    console.log(optsov);
    if (optsov) {
      if (optsov.price_prefix === "+") {
        ppet -= Number(optsov.price_excluding_tax);
        ppit -= Number(optsov.price);
      } else {
        ppet += Number(optsov.price_excluding_tax);
        ppit += Number(optsov.price);
      }
    }
    const newOption = JSON.parse(
      e.target[e.target.selectedIndex].getAttribute(
        "data-product-option-details"
      )
    );
    console.log("newOption", newOption);
    if (newOption !== null) {
      if (newOption.price_prefix === "+") {
        ppet += Number(newOption.price_excluding_tax);
        ppit += Number(newOption.price);
      } else {
        ppet -= Number(newOption.price_excluding_tax);
        ppit -= Number(newOption.price);
      }
      let pf = { ...productForm };
      let ops = { ...productForm.option };
      let ods = [...productForm.option_details];
      ods = ods.filter((oo) => {
        return oo.product_option_id != e.target.getAttribute("name");
      });
      ops[e.target.getAttribute("name")] = newOption.product_option_value_id;
      ods.push({
        product_option_id: e.target.getAttribute("name"),
        name: opts.name,
        value: newOption.name,
      });
      pf.price = ppit;
      pf.price_excluding_tax = ppet;
      pf.option = ops;
      pf.option_details = ods;
      setProductForm(pf);
      setProductPrice(ppit);
    } else {
      let pf = { ...productForm };
      let ops = { ...productForm.option };
      let ods = [...productForm.option_details];
      ods = ods.filter((oo) => {
        return oo.product_option_id != e.target.getAttribute("name");
      });
      ops[e.target.getAttribute("name")] = "";
      pf.price = ppit;
      pf.price_excluding_tax = ppet;
      pf.option = ops;
      pf.option_details = ods;
      setProductForm(pf);
      setProductPrice(ppit);
    }
  };

  const handleIncrement = (e) => {
    setQuantity(Number(quantity) + Number(productDetails.minimum));
  };

  const handleDecrement = (e) => {
    if (quantity > productDetails.minimum) {
      setQuantity(Number(quantity) - Number(productDetails.minimum));
    }
  };

  const handleCustomQuantityInput = (e) => {
    setQuantity(Number(e.target.value));
  };

  useEffect(() => {
    setLoaded(0);
    setTimeout(function () {
      setLoaded(1);
    }, 2000);
    setProductDetails(undefined);

    const fetchData = async () => {
      try {
        let product = await httpService.get(
          httpService.apiEndpoint +
            "feed/rest_api/products&store_id=3&id=" +
            params.id,

          { headers: { ...httpService.headers, "X-Oc-Currency": currency } }
        );
        product = product.data.data;
        console.log(product);
        setProductDetails(product);
        setQuantity(product.minimum);

        let initialProductPrice = product.price;
        let initialProductPriceExcludingTax = product.price_excluding_tax;
        let option_details = [];
        let option = {};
        if (product.options && product.options.length !== 0) {
          product.options.map((opt, index) => {
            if (Number(opt.parent_id) === 0 && opt.type === "radio") {
              initialProductPrice =
                initialProductPrice + opt.option_value[0].price;
              initialProductPriceExcludingTax =
                initialProductPriceExcludingTax +
                opt.option_value[0].price_excluding_tax;

              option_details.push({
                product_option_id: opt.product_option_id,
                product_option_value_id:
                  opt.option_value[0].product_option_value_id,
                option_id: opt.option_id,
                option_value_id: opt.option_value[0].option_value_id,
                name: opt.name,
                value: opt.option_value[0].name,
                type: opt.type,
                price: opt.option_value[0].price,
                price_excluding_tax: opt.option_value[0].price_excluding_tax,
                parent_id: opt.parent_id,
              });

              option[opt.product_option_id] =
                opt.option_value[0].product_option_value_id;
            }
          });
        }

        if (product.options && product.options.length !== 0) {
          product.options.map((opt, index) => {
            if (Number(opt.parent_id) !== 0 && opt.type === "radio") {
              let put = false;
              opt.option_value.map((ov) => {
                if (put === false) {
                  if (
                    ov.parent_option_values.split(", ").includes(
                      String(
                        option_details.filter((op) => {
                          return Number(op.option_id) === Number(opt.parent_id);
                        })[0].option_value_id
                      )
                    )
                  ) {
                    console.log("in here");
                    put = true;

                    initialProductPrice = initialProductPrice + ov.price;
                    initialProductPriceExcludingTax =
                      initialProductPriceExcludingTax + ov.price_excluding_tax;

                    option_details.push({
                      product_option_id: opt.product_option_id,
                      product_option_value_id: ov.product_option_value_id,
                      option_id: opt.option_id,
                      option_value_id: ov.option_value_id,
                      name: opt.name,
                      value: ov.name,
                      type: opt.type,
                      price: ov.price,
                      price_excluding_tax: ov.price_excluding_tax,
                      parent_id: opt.parent_id,
                    });

                    option[opt.product_option_id] = ov.product_option_value_id;
                  }
                }
              });
            }
          });
        }
        if (product.options && product.options.length !== 0) {
          product.options.map((opt, index) => {
            if (opt.type !== "radio") {
              option[opt.product_option_id] = "";
            }
          });
        }

        setProductPrice(initialProductPrice);

        const form = {
          product_id: product.product_id,
          name: product.name,
          model: product.model,
          quantity: product.minimum,
          price: initialProductPrice,
          price_excluding_tax: initialProductPriceExcludingTax,
          total: initialProductPrice * product.minimum,
          total_excluding_tax:
            initialProductPriceExcludingTax * product.minimum,
          option_details: option_details,
          option: option,
          minimum: product.minimum,
        };
        setProductForm(form);

        const { data: relProducts } = await httpService.get(
          httpService.apiEndpoint +
            "feed/rest_api/related&store_id=3&id=" +
            params.id,
          { headers: httpService.headers }
        );
        if ("success" in relProducts && relProducts.success === 1) {
          setRelatedProducts(relProducts.data);
        } else {
          setRelatedProducts([]);
        }
      } catch (error) {
        console.log("product page error", error);
      }
    };
    fetchData();
  }, [params.id, currency]);

  return (
    <div>
      <Header />
      {!isLoaded && <Curtain />}
      <section className="section dark-bg ">
        {productDetails ? (
          <div className="container mt-100 mb-30 mh-70">
            <div className="row d-flex align-items-center">
              <div className="col-12 col-sm-7">
                <ProductSlider
                  images={[
                    productDetails.original_image,
                    ...productDetails.original_images,
                  ]}
                />
              </div>

              <div className="col-sm-5 col-12">
                <ProductInfo
                  productDetails={productDetails}
                  productPrice={productPrice}
                  quantity={quantity}
                  handleIncrement={handleIncrement}
                  handleDecrement={handleDecrement}
                  handleCustomQuantityInput={handleCustomQuantityInput}
                  handleAddToCart={handleAddToCart}
                  buttonsDisabled={buttonsDisabled}
                  handleOtherOptionChange={handleOtherOptionChange}
                  productForm={productForm}
                />
              </div>
            </div>
            <br />
            {relatedProducts ? (
              relatedProducts.length !== 0 ? (
                <YouMayLike products={relatedProducts} />
              ) : null
            ) : (
              <Loader />
            )}
          </div>
        ) : (
          <Loading />
        )}
      </section>

      <FooterDesktop />
    </div>
  );
};

export default Product;
