import React from 'react'
import Header from '../../common/Header';
import FooterDesktop from '../../common/FooterDesktop';
import about from './about.jpeg';
import shikha from './shikha.jpeg';

const Aboutus = () => {
  return (
    <div>
        <Header />
        <section className='dark-bg about-section ptb-30'>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <img src={about} alt="" className="img-fluid mt-80" />
                    </div>
                </div>
                <br /><br />
                <div className="row d-flex align-items-center">
                    <div className="col-sm-5 col-12">
                        <img src={shikha} alt="" className="img-fluid" />
                    </div>
                    <div className="col-sm-7 col-12">
                        <h1 className='mt-30'>Our Story</h1>
                        <p className="text-white mt-10 font-14">
Shikha Durlabhji, our founder, translated her love for wildlife into wildlife collectibles and accessories that evoke grandeur to take you on a visual vacation. Code Silver Wild's products are elegance redefined and are crafted exclusively for wildlife lovers and enthusiasts who wish to take a little bit of the wilderness back home.
<br /><br />
Be it home or office decor, jewellery, animal figurines, barware, travel accessories and more, we keep a wide collection of bespoke designs. We offer customizable designs to choose from for gifting your loved ones. Our designs are inspired by photographs taken by well-renowned wildlife photographers from across the globe and we take great pride in the finishing of each product.
<br /><br />

After completing school from Maharaja Sawai Man Singh Vidyalaya, Jaipur in 2004, Shikha joined Miranda House, University of Delhi. She read History in college. At Miranda House, Shikha organized a wide spectrum of activities – Inter-College festival (Tarikh), South-Asian Students Seminar Debate (Manzar). She was also President of the History Society and the Adventure Club. After graduating in 2008, she undertook a 6-month course in photography from Triveni Kala Sangam in Delhi.
<br /><br />

Though she still loves photography, she has realized that photography is not something she wishes to pursue professionally. After much deliberation, Shikha decided to foray into silver gift items. After several months of research, she launched her own specialty line (in Jaipur) called Code Silver on September 1, 2010.
<br /><br />

Shikha's affair with wildlife led to the birth of Code Silver Wild.</p>
                    </div>
                </div>
            </div>
        </section>
        <FooterDesktop />
    </div>
  )
}

export default Aboutus