import React from "react";
import Carousel from "react-multi-carousel";
import { Link } from "react-router-dom";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  mobile: {
    breakpoint: { max: 1950, min: 0 },
    items: 1,
  },
};

const ProductSlider = ({ images }) => {
  return (
    <div className="row">
      <div className="col-12">
        <Carousel
          responsive={responsive}
          arrows={true}
          className="trending-slider"
        >
          {images ? (
            images.map((image, index) => {
              return (
                <div key={index} className="mySlider-slide">
                  <img src={image} alt="" className="img-fluid" />
                </div>
              );
            })
          ) : (
            <div></div>
          )}
        </Carousel>
      </div>
    </div>
  );
};

export default ProductSlider;
