import React from 'react'
import bg from './bg.jpg';
import banner from './banner.png';

const Behind = () => {
  return (
    <section className="behind-scene ptb-30" style={{backgroundImage: `url(${bg})`}}>
        <div className="container">
            <div className="row d-flex align-items-center">
              
                <div className="col-sm-7 col-12 wild-banner mobile-text-center">
                    <img src={banner} alt="" className="img-fluid m-auto" />
                </div>
                <div className="col-sm-5 col-12 mobile-mt-20 mobile-text-center">
                    <h1 className="text-white">Wilderness Is Home</h1>
                        <p className="text-white font-14 mb-0 mt-10">
                        Shikha Durlabhji, our founder, translated her love for wildlife and wilderness into wildlife collectibles and accessories that evoke grandeur to take you on a visual vacation.
                        <br /><br />
                        Code Silver Wild's products are elegance redefined and are crafted exclusively for wildlife lovers and enthusiasts who wish to take a little bit of the wilderness back home.
                         </p>
                         <br />
                         <button className="btn btn-primary btn-gold w-100 mb-30">
           LEARN MORE
          </button>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Behind