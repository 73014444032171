import React from "react";
import Header from "../common/Header";
import FooterDesktop from "../common/FooterDesktop";

const Return = () => {
  return (
    <div>
      <Header />
      <section className="mh-70">
        <div className="container mb-30">
          <div className="row">
            <div className="col-12 text-center">
              <h1 className="mt-100">Returns & Refund Policy</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <p className="font-14 text-description mt-30">
                Right for you to cancel your contract:
                <br /><br />
                 <b>1.1 Subject to 3.3 below,</b>
                you may cancel your contract with us for the goods you order at
                any time before dispatch of the product. Products once sold will
                not be return, in case of damage product received that can be
                replaced up to the end of the seventh working day from the date
                you receive the ordered goods. You do not need to give us any
                reason for cancelling your contract nor will you have to pay any
                penalty. 
                <br />
                1.2 To cancel your contract you must notify us by email
                at info@codesilver.in or telephone us on +91-141-4045116.
                <br />
                 1.3
                Your right to cancel your contract with us for the goods you
                have ordered is subject to: a) You calling us before dispatch of
                the goods. b) The item(s) cannot be a Bespoke Special Order,
                which due to their unique nature, cannot be exchanged or
                returned under any circumstances.
                <br />
                 1.4 Once you have notified us
                that you are cancelling your contract, any sum debited from your
                credit card will be credited to your account as soon as possible
                and in any event within 30 days of your order provided hat the
                goods in question are returned by you and received by us in the
                condition they were in when delivered to you. If you do not
                return the goods delivered to you, in the way detailed above, we
                shall be entitled to deduct the direct costs of recovering the
                goods from the amount to be credited to you. 
                <br /><br />
                2. Cancellation by
                us: 
                <br />
                2.1 We reserve the right to cancel the contract between you
                and us if: 
                <br />
                2.1.1 We have insufficient stock to deliver the goods
                you have ordered; <br />
                2.1.2 We do not deliver to your area;
                <br />
                 or 2.1.3
                One or more of the items you ordered was listed at an incorrect
                price due to a typographical error or an error in the pricing
                information set by us from our management. 
                <br />
                2.2 If we do cancel
                your contract we will notify you by email or by telephone, and
                will re-credit to your account any sum deducted by us from your
                account as soon as possible but in any event within 30 days of
                your order. We will not be obliged to offer any additional
                compensation for disappointment suffered.
              </p>
            </div>
          </div>
        </div>
      </section>
      <FooterDesktop />
    </div>
  );
};

export default Return;
