import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { CatalogContext } from "../../contexts";

const Mostwanted = () => {
  const { mostWantedProducts } = useContext(CatalogContext);
  return (
    <section className="most-wanted dark-section pt-50">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <h1>Most Wanted</h1>
            <p className="text-offwhite">
              Sneak-Peak into our most popular design.
            </p>
          </div>
        </div>
        <div className="row">
          {mostWantedProducts.slice(0, 4).map((product) => {
            return (
              <Link
                to={`/product/${product.product_id}`}
                className="col-sm-3 col-6"
                key={product.product_id}
              >
                <div className="card card-styled card-styled-dark text-center">
                  <img
                    src={product.original_image}
                    alt=""
                    className="img-fluid"
                  />
                  <p className="font-14 mb-0 mt-10">
                    <b>{product.name}</b>
                  </p>
                  <p className="mb-0">{product.price_formated}</p>
                </div>
              </Link>
            );
          })}
        </div>
        <div className="row">
          <div className="col-6 offset-3 mt-20 text-center">
            <Link
              to={`/categories/100`}
              className="btn btn-primary btn-gold w-100 "
            >
              View All
            </Link>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="divider mt-30"></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Mostwanted;
