import React from 'react';
import Header from "../../common/Header";
import FooterDesktop from "../../common/FooterDesktop";
import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';

import i1 from './imgs/1.jpeg';
import i2 from './imgs/2.jpeg';
import i3 from './imgs/3.jpeg';
import i4 from './imgs/4.jpeg';
import i5 from './imgs/5.jpeg';
import i6 from './imgs/6.jpeg';
import i7 from './imgs/7.jpeg';
import i8 from './imgs/8.jpeg';

import i9 from './imgs/9.jpeg';
import i10 from './imgs/10.jpeg';
import i11 from './imgs/11.jpeg';
import i12 from './imgs/12.jpeg';

const IntoWild = () => {
    const images = [
        {src: i1},
        {src: i2},
        {src: i3},
        {src: i4},
        {src: i5},
        {src: i6},
        {src: i7},
        {src: i8},
        {src: i9},
        {src: i10},
        {src: i11},
        {src: i12},
        
      ];
  return (
    <div>
        <Header />
            <section className="mh-70">
                <div className="container mb-30">
                    <div className="row">
                        <div className="col-12 text-center">
                            <h1 className="mt-100">Into The Wild</h1>
                            <p className="mb-0 text-description">For our love for wildlife, these are personal clicks by founders of Code Silver Wild.
                                 Take a dive into wilderness.</p>
                        </div>
                        <div className="col-sm-8 offset-sm-2 col-12 offset-0 mt-30">
                        <Carousel images={images} />
                        </div>
                    </div>
                </div>
            </section>
        <FooterDesktop />
    </div>
  )
}

export default IntoWild