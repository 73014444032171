import React, { useContext } from "react";
import { Link } from "react-router-dom";

import { CatalogContext } from "../../contexts";

const Bareware = () => {
  const { barWare } = useContext(CatalogContext);
  return (
    <section className="most-wanted dark-section mt-0">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <h1>Barware</h1>
            <p className="text-offwhite">
              Shop wildlife inspired suave barware
            </p>
          </div>
        </div>

        <div className="row">
          <div className="row-grid">
            <div className="column c4">
              {barWare.length > 0 ? (
                <Link to={`/product/${barWare[0].product_id}`}>
                  <img
                    src={barWare[0].original_image}
                    style={{ width: "100%" }}
                  />
                </Link>
              ) : null}

              {barWare.length > 1 ? (
                <Link to={`/product/${barWare[1].product_id}`}>
                  <img
                    src={barWare[1].original_image}
                    style={{ width: "100%" }}
                  />
                </Link>
              ) : null}
            </div>
            <div className="column c5">
              {barWare.length > 2 ? (
                <Link to={`/product/${barWare[2].product_id}`}>
                  <img
                    src={barWare[2].original_image}
                    style={{ width: "100%" }}
                  />
                </Link>
              ) : null}
              {barWare.length > 3 ? (
                <Link to={`/product/${barWare[3].product_id}`}>
                  <img
                    src={barWare[3].original_image}
                    style={{ width: "100%" }}
                  />
                </Link>
              ) : null}
            </div>
            <div className="column c6">
              {barWare.length > 4 ? (
                <Link to={`/product/${barWare[4].product_id}`}>
                  <img
                    src={barWare[4].original_image}
                    style={{ width: "100%" }}
                  />
                </Link>
              ) : null}
              {barWare.length > 5 ? (
                <Link to={`/product/${barWare[5].product_id}`}>
                  <img
                    src={barWare[5].original_image}
                    style={{ width: "100%" }}
                  />
                </Link>
              ) : null}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-6 offset-3 mt-20 text-center">
            <Link
              to={`/categories/62`}
              className="btn btn-primary btn-gold w-100 mb-30"
            >
              SHOP NOW
            </Link>
            <br /> <br />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Bareware;
