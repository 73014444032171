import React from "react";

const PayPalForm = ({ payPalForm: paypal }) => {
  return (
    <div>
      <form style={{"display":"none"}} action={paypal.action} method="post" id="paypal_form">
        <input type="hidden" name="cmd" defaultValue="_cart" />
        <input type="hidden" name="upload" defaultValue={1} />
        <input type="hidden" name="business" defaultValue={paypal.business} />
        <input
          type="hidden"
          name="currency_code"
          defaultValue={paypal.currency_code}
        />
        <input
          type="hidden"
          name="first_name"
          defaultValue={paypal.first_name}
        />
        <input type="hidden" name="last_name" defaultValue={paypal.last_name} />
        <input type="hidden" name="address1" defaultValue={paypal.address1} />
        <input type="hidden" name="address2" defaultValue={paypal.address2} />
        <input type="hidden" name="city" defaultValue={paypal.city} />
        <input type="hidden" name="zip" defaultValue={paypal.zip} />
        <input type="hidden" name="country" defaultValue={paypal.country} />
        <input type="hidden" name="address_override" defaultValue={0} />
        <input type="hidden" name="email" defaultValue={paypal.email} />
        <input type="hidden" name="invoice" defaultValue={paypal.address1} />
        <input type="hidden" name="lc" defaultValue={paypal.lc} />
        <input type="hidden" name="rm" defaultValue={2} />
        <input type="hidden" name="no_note" defaultValue={1} />
        <input type="hidden" name="no_shipping" defaultValue={1} />
        <input type="hidden" name="charset" defaultValue="utf-8" />
        <input type="hidden" name="return" defaultValue="https://wild.codesilver.in/cart" />
        <input
          type="hidden"
          name="notify_url"
          defaultValue={paypal.notify_url}
        />
        <input
          type="hidden"
          name="cancel_return"
          defaultValue="https://wild.codesilver.in/cart"
        />
        <input type="hidden" name="paymentaction" defaultValue="sale" />
        <input type="hidden" name="custom" defaultValue={paypal.custom} />
        <input type="hidden" name="bn" defaultValue="OpenCart_2.0_WPS" />
        {paypal.discount_amount_cart && (
          <input
            type="hidden"
            name="discount_amount_cart"
            value={paypal.discount_amount_cart}
          />
        )}
        {paypal.products.map((product, index) => {
          return (
            <>
              <input
                type="hidden"
                name={`item_name_${index + 1}`}
                value={product.name}
              />
              <input
                type="hidden"
                name={`item_number_${index + 1}`}
                value={product.model}
              />
              <input
                type="hidden"
                name={`amount_${index + 1}`}
                value={product.price}
              />
              <input
                type="hidden"
                name={`quantity_${index + 1}`}
                value={product.quantity}
              />
              <input
                type="hidden"
                name={`weight_${index + 1}`}
                value={product.weight}
              />
              {product.option.map((option, j) => {
                return (
                  <>
                    <input
                      type="hidden"
                      name={`on${j + 1}_${index + 1}`}
                      value={option.name}
                    />
                    <input
                      type="hidden"
                      name={`os${j + 1}_${index + 1}`}
                      value={option.value}
                    />
                  </>
                );
              })}
            </>
          );
        })}

        <div className="buttons">
          <div className="pull-right">
            <input type="submit" className="btn btn-primary submit-paypal" style={{"display":"none"}}/>
          </div>
        </div>
      </form>
    </div>
  );
};

export default PayPalForm;
