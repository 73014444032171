import { createContext, useState, useEffect, useContext } from "react";
import http from "../services/httpService"
import { SessionContext } from "./SessionContext";

const CatalogContext = createContext();


const CatalogProvider = (props)=>{
    const{currency} = useContext(SessionContext);
    const[topBanners, setTopBanners] = useState([]);
    const[topBannersMobile, setTopBannersMobile] = useState([]);

    const[mostWantedProducts, setMostWanterProducts] = useState([]);
    const[barWare, setBarware] = useState([]);
    const[homeAndDecor, setHomeAndDecor] = useState([]);
    const[barWareCategory, setBarwareCategory] = useState();
    const[homeAndDecorCategory, setHomeAndDecorCategory] = useState();
    const[jewelleryCategory, setJewelleryCategory] = useState();
    const[officeCategory, setOfficeCategory] = useState();
    const[travelCategory, setTravelCategory] = useState();
    const[allProducts, setAllProducts] = useState([]);
    const [toastStatus,setToastStatus] = useState({
        'type':'error',
        'heading':'Invalid Data',
        'msg':'Invalid Form Data',
        'status':'hide'
      });


    function shuffle(array) {
        let currentIndex = array.length,  randomIndex;
      
        // While there remain elements to shuffle.
        while (currentIndex != 0) {
      
          // Pick a remaining element.
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex--;
      
          // And swap it with the current element.
          [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
        }
      
        return array;
      }


    useEffect(()=>{
        const fetchData = async()=>{
          

            const {data:fetchedMostWantedProducts} = await http.get(http.apiEndpoint+"feed/rest_api/products&category=100&store_id=3",{ headers: { ...http.headers, "X-Oc-Currency": currency } });
            if(typeof fetchedMostWantedProducts == "object" && "success" in fetchedMostWantedProducts && fetchedMostWantedProducts.success ===1){
                setMostWanterProducts(shuffle(fetchedMostWantedProducts.data));
            }

            const {data:fetchedBarWare} = await http.get(http.apiEndpoint+"feed/rest_api/products&category=62&store_id=3",{ headers: { ...http.headers, "X-Oc-Currency": currency } });
            
            if(typeof fetchedBarWare == "object" && "success" in fetchedBarWare && fetchedBarWare.success ===1){
                setBarware(shuffle(fetchedBarWare.data));
            }

            const {data:fetchedHomeAndDecor} = await http.get(http.apiEndpoint+"feed/rest_api/products&category=59&store_id=3",{ headers: { ...http.headers, "X-Oc-Currency": currency } });
            
            if(typeof fetchedHomeAndDecor == "object" && "success" in fetchedHomeAndDecor && fetchedHomeAndDecor.success ===1){
                setHomeAndDecor(shuffle(fetchedHomeAndDecor.data));
            }

            const {data:fetchedJewelleryCategory} = await http.get(http.apiEndpoint+"feed/rest_api/categories&id=63&store_id=3",{ headers: { ...http.headers, "X-Oc-Currency": currency } });
            
            if(typeof fetchedJewelleryCategory == "object" && "success" in fetchedJewelleryCategory && fetchedJewelleryCategory.success ===1){
                setJewelleryCategory(fetchedJewelleryCategory.data);
            }

            const {data:fetchedOfficeCategory} = await http.get(http.apiEndpoint+"feed/rest_api/categories&id=61&store_id=3",{ headers: { ...http.headers, "X-Oc-Currency": currency } });
            
            if(typeof fetchedOfficeCategory == "object" && "success" in fetchedOfficeCategory && fetchedOfficeCategory.success ===1){
                setOfficeCategory(fetchedOfficeCategory.data);
            }

            const {data:fetchedTravelCategory} = await http.get(http.apiEndpoint+"feed/rest_api/categories&id=60&store_id=3",{ headers: { ...http.headers, "X-Oc-Currency": currency } });
            
            if(typeof fetchedTravelCategory == "object" && "success" in fetchedTravelCategory && fetchedTravelCategory.success ===1){
                setTravelCategory(fetchedTravelCategory.data);
            }

            const {data:fetchedBarwareCategory} = await http.get(http.apiEndpoint+"feed/rest_api/categories&id=62&store_id=3",{ headers: { ...http.headers, "X-Oc-Currency": currency } });
            
            if(typeof fetchedBarwareCategory == "object" && "success" in fetchedBarwareCategory && fetchedBarwareCategory.success ===1){
                setBarwareCategory(fetchedBarwareCategory.data);
            }

            const {data:fetchedHomeAndDecorCategory} = await http.get(http.apiEndpoint+"feed/rest_api/categories&id=59&store_id=3",{ headers: { ...http.headers, "X-Oc-Currency": currency } });
            
            if(typeof fetchedHomeAndDecorCategory == "object" && "success" in fetchedHomeAndDecorCategory && fetchedHomeAndDecorCategory.success ===1){
                setHomeAndDecorCategory(fetchedHomeAndDecorCategory.data);
            }

            const {data:fetchedAllProducts} = await http.get(http.apiEndpoint+"feed/rest_api/products&store_id=3&limit=1000",{ headers: { ...http.headers, "X-Oc-Currency": currency } });
            if(typeof fetchedAllProducts == "object" && "success" in fetchedAllProducts && fetchedAllProducts.success ===1){
                setAllProducts(fetchedAllProducts.data);
            }
        }
        
        fetchData();
    },[currency])
    useEffect(()=>{
        const fetchData = async()=>{
            const {data:fetchedTopBanners} = await http.get(http.apiEndpoint+"feed/rest_api/banners&id=9",{ headers: { ...http.headers, "X-Oc-Currency": currency } });
            if(typeof fetchedTopBanners == "object" && "success" in fetchedTopBanners && fetchedTopBanners.success ===1){
                setTopBanners(fetchedTopBanners.data);
            }

            const {data:fetchedTopBannersMobile} = await http.get(http.apiEndpoint+"feed/rest_api/banners&id=15",{ headers: { ...http.headers, "X-Oc-Currency": currency } });
            if(typeof fetchedTopBannersMobile == "object" && "success" in fetchedTopBannersMobile && fetchedTopBannersMobile.success ===1){
                setTopBannersMobile(fetchedTopBannersMobile.data);
            }

           
        }
        
        fetchData();
    },[])
    

    const getProductFromProductsList = (product_id) => {
        let result = null;
        allProducts.forEach((product, index) => {
          if (Number(product.product_id) === Number(product_id)) {
            result = product;
          }
        });
        return result;
      };


      const showToast = (type,heading,msg) =>{
        const info = {type,heading,msg,status:'show'};
        setToastStatus(info);
        setTimeout(function(){
          setToastStatus({...info,status:'hide'})
        },2500)
      }

    return(
        <CatalogContext.Provider value={{toastStatus,showToast, topBanners, topBannersMobile,mostWantedProducts, barWare, homeAndDecor, jewelleryCategory, officeCategory,travelCategory, barWareCategory, homeAndDecorCategory, getProductFromProductsList}}>
            {props.children}
        </CatalogContext.Provider>
    )
}

export {CatalogContext, CatalogProvider};