import React, { useState, useEffect } from 'react';
import BigFooter from './BigFooter';
import FooterMobile from './FooterMobile';

const FooterDesktop = (props) => {

  const [isDesktop, setDesktop] = useState(window.innerWidth > 650);
  const updateMedia = () => {
      setDesktop(window.innerWidth > 765);
    };
    useEffect(() => {
      window.addEventListener("resize", updateMedia);
      return () => window.removeEventListener("resize", updateMedia);
    });
   
  return (
    <>
     {isDesktop ? <BigFooter/>: <FooterMobile page={props.page}/>}
    </>
   
    
  );

};

export default FooterDesktop;
