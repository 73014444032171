import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { CatalogContext, SessionContext, CartContext } from "../../../contexts";
import QuantityInputButtons from "../../quantityInputButtons/QuantityInputButtons";

const CartItem = ({ cartItem, updateQuantity, deleteFromCart }) => {
  const { formatPrice } = useContext(CartContext);
  const { currency } = useContext(SessionContext);
  const { getProductFromProductsList } = useContext(CatalogContext);
  console.log(getProductFromProductsList);
  return (
    <tr className="vertical-middle">
      <td className="text-center">
        <Link to={`/product/${cartItem.product_id}`}>
          <img
            className="cart-img"
            src={
              getProductFromProductsList(cartItem.product_id) &&
              getProductFromProductsList(cartItem.product_id).original_image
            }
            alt={cartItem.name}
            title={cartItem.name}
          />
        </Link>
      </td>
      <td className="text-center">
        <Link to={`/product/${cartItem.product_id}`}>
          <p className="mb-0 font-16 font-600 text-description">
            {cartItem.name}
          </p>
          <span>
            {cartItem.option && cartItem.option.lenghth !== 0
              ? cartItem.option_details.map((option) => {
                  return (
                    <p
                      key={option.option_id}
                      style={{ color: "#B0A9A9", margin: "0" }}
                    >
                      {option.name}:&nbsp;{option.value}
                    </p>
                  );
                })
              : null}
          </span>
        </Link>
      </td>

      <td className="text-center">
        <QuantityInputButtons
          quantity={cartItem.quantity}
          handleIncrement={() => updateQuantity(cartItem, "increment")}
          handleDecrement={() => updateQuantity(cartItem, "decrement")}
          handleCustomQuantityInput={() => {
            console.log("custom");
          }}
        ></QuantityInputButtons>
      </td>

      <td className="text-center">
        <p className="text-description font-16 mb-0">
          {(currency === "USD" ? "$" : "₹") +
            formatPrice(cartItem.price_excluding_tax)
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </p>
      </td>
      <td className="text-center">
        <p className="text-description font-16 mb-0">
          {(currency === "USD" ? "$" : "₹") +
            formatPrice(cartItem.total_excluding_tax)
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </p>
      </td>
      <td style={{ textAlign: "center", paddingRight: 30 }}>
        <button
          className="btn btn-counter"
          onClick={() => deleteFromCart(cartItem)}
        >
          <i className="bi bi-x font-16"></i>
        </button>
      </td>
    </tr>
  );
};

export default CartItem;
