import React from "react";
import "./toast.css";

const Toast = ({ props }) => {
  const classes =
    props.type === "error"
      ? "toast toast-bar toast-top rounded-l bg-gold shadow-bg shadow-bg-s"
      : "toast toast-bar toast-top rounded-l bg-gold shadow-bg shadow-bg-s";
  const icon =
    props.type === "error"
      ? "text-gold bi-exclamation-triangle-fill"
      : "text-gold bi-check-circle-fill";

  return (
    <div id="toast-top-2" className={`${classes} ${props.status}`}>
      <div className="align-self-center">
        <i
          className={`icon icon-s bg-white ${icon} rounded-l show shadow-s bi  font-22 me-3`}
        />
      </div>
      <div className="align-self-center">
        <strong className="font-13 text-white">{props.heading}</strong>
      </div>
      <div className="align-self-center ms-auto">
        <button
          type="button"
          className="btn-close btn-close-white me-2 m-auto font-9"
          data-bs-dismiss="toast"
        />
      </div>
    </div>
  );
};

export default Toast;
