import React from "react";
import { Link } from "react-router-dom";
import Header from "../../common/Header";
import FooterDesktop from "../../common/FooterDesktop";
import empty from "./empty-cart.png";

const EmptyCart = () => {
  return (
    <div>
      <section className="mh-70">
        <div className="container mt-100">
          <div className="row text-center ">
            <img src={empty} alt="" className="img-fluid  empty-cart" />
            <h1 className="mtb-20">Your Cart Is Empty</h1>
            <Link to="/" className="btn btn-primary btn-gold ">
              Continue Shopping
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
};

export default EmptyCart;
