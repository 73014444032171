import React from 'react'
import Header from '../common/Header';
import FooterDesktop from '../common/FooterDesktop';
import preload from './preload.gif';

const Curtain = () => {
  return (
    <div>
         <div className="curtain">
          <Header />
            <div className="section h-80 text-center d-flex align-item-center">
                <img src={preload} alt="" className="img-fluid m-auto w-100 opacity-2" />
            </div>
          <FooterDesktop page='/'/>
        </div>
    </div>
  )
}

export default Curtain