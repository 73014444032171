import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

function hideSearch() {
  document.getElementById("search-form").style.display = "none";
}

const SearchForm = () => {
  const [searchTerm, setSearchTerm] = useState("");

  const searchInput = useRef(null);

  const navigate = useNavigate();

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearch = () => {
    if (searchInput.current.value) {
      navigate(`/search/${searchInput.current.value}`);
      hideSearch();
    }
    
  };

  const handleKeyDown=(e)=>{
    if(e.keyCode === 13){
      e.preventDefault();
      navigate(`/search/${searchInput.current.value}`);
      hideSearch();
    }
  }
  return (
    <div id="search-form">
      <button
        className="btn btn-counter search-btn"
        onClick={() => hideSearch()}
      >
        <i className="bi bi-x font-24"></i>
      </button>

      <div className="search-mobile-box">
        <input
          ref={searchInput}
          onChange={(e) => handleChange(e)}
          value={searchTerm}
          type="text"
          className="search-input-box"
          placeholder="Search For Products"
          style={{ zIndex: "999" }}
          onKeyDown={(e)=>handleKeyDown(e)}
        />

        <i
          onClick={handleSearch}
          style={{ cursor: "pointer", zIndex: "9999" }}
          className="abcd bi bi-search font-18 mob-search-icon"
        ></i>
      </div>
    </div>
  );
};

export default SearchForm;
