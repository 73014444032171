import React from "react";
import payment from "../../imgs/visa_list.png";
import { Link } from "react-router-dom";
import BottomNav from "./BottomNav";

const FooterMobile = (props) => {
  
  return (
    <footer className="mobile_footer pt-20 pb-60">
      <div className="container">
     
        <div className="accordion accordion-m border-0" id="accordion-group-5">
          <div className="accordion-item">
            <button
              className="accordion-button px-0 ps-1 collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#accordion5-1"
            >
              <span className="font-600 font-14 text-gold">
                Customer Service
              </span>
              <i className="bi bi-plus font-20 text-gold" />
            </button>
            <div
              id="accordion5-1"
              className="accordion-collapse"
              data-bs-parent="#accordion-group-5"
            >
              <div className="row">
                <div className="col-12">
                  <Link to="../contact" className="text-gold">
                    Contact Us
                  </Link>
                  <br />
                  <Link to="../terms" className="text-gold">
                    Terms & Conditions
                  </Link>
                  <br />
                  <Link to="../privacy" className="text-gold">
                    Privacy Policy
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <button
              className="accordion-button px-0 ps-1 collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#accordion5-2"
            >
              <span className="font-600 font-14 text-gold">Compnay Info</span>
              <i className="bi bi-plus font-20 text-gold" />
            </button>
            <div
              id="accordion5-2"
              className="accordion-collapse collapse"
              data-bs-parent="#accordion-group-5"
            >
              <div className="row">
                <div className="col-12">
                  <Link to="../aboutus" className="text-gold">
                    About Us
                  </Link>
                  <br />
                  <Link to="../behind" className="text-gold">
                    Behind The Scenes
                  </Link>
                  <br />
                  <Link to="../intowild" className="text-gold">
                    Into The Wild
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <button
              className="accordion-button px-0 ps-1 collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#accordion5-3"
            >
              <span className="font-600 font-14 text-gold">Get In Touch</span>
              <i className="bi bi-plus font-20 text-gold" />
            </button>
            <div
              id="accordion5-3"
              className="accordion-collapse collapse"
              data-bs-parent="#accordion-group-5"
            >
              <div className="row">
                <div className="col-12">
                <Link to="" className="text-gold"><i className="bi bi-telephone"></i>&nbsp; +91 99500 90777</Link><br />
            <Link to="" className="text-gold"><i className="bi bi-telephone"></i>&nbsp; +91 99500 90179</Link><br />
            <Link to="" className="text-gold"><i className="bi bi-envelope"></i>&nbsp; info@codesilver.in</Link>
        
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row footer_links_social">
        <div className="col-12 text-center">
               
               <input type="text" placeholder="Your Email" className="subscribe-box-mobile" />
               <button className="subscribe-btn">Subscribe</button>

               </div>
                <div className="col-12 mt-20 text-center">
                <img src={payment} alt="" className="img-fluid pay-links-mobile" />
                <p className="mb-0">&copy; 2022 All Rights Reserve</p>
                </div>
            </div>
      </div>
      <BottomNav page={props.page} />
    </footer>
    
  );
};

export default FooterMobile;
