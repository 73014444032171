import React from "react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { CartContext, CatalogContext, SessionContext } from "../../../contexts";

const CartItem = ({ cartItem, updateQuantity, deleteFromCart }) => {
  const { formatPrice } = useContext(CartContext);
  const { currency } = useContext(SessionContext);
  const { getProductFromProductsList } = useContext(CatalogContext);
  return (
    <div className="row d-flex align-items-center">
      <div className="col-6">
        <Link to={`/product/${cartItem.product_id}`}>
          <img
            className="img-fluid"
            src={
              getProductFromProductsList(cartItem.product_id) &&
              getProductFromProductsList(cartItem.product_id).original_image
            }
            alt={cartItem.name}
            title={cartItem.name}
          />
        </Link>
      </div>
      <div className="col-6">
        <Link to="../product">
          <p className="mb-0 font-15 font-600 text-description mb-0">
            {cartItem.name}
          </p>
          <span>
            {cartItem.option && cartItem.option.lenghth !== 0
              ? cartItem.option_details.map((option) => {
                  return (
                    <p
                      key={option.option_id}
                      style={{ color: "#B0A9A9", margin: "0" }}
                    >
                      {option.name}:&nbsp;{option.value}
                    </p>
                  );
                })
              : null}
          </span>
        </Link>
        <p className="mb-0 font-15 text-description mb-0">
          Qty: {cartItem.quantity}
        </p>
        <p className="mb-0 font-15 text-gold mb-0">
          Price:{" "}
          {(currency === "USD" ? "$" : "₹") +
            formatPrice(cartItem.price_excluding_tax)
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </p>
        <p className="mb-0 font-15 text-description mb-0">
          <i
            onClick={() => deleteFromCart(cartItem)}
            className="bi bi-trash"
          ></i>{" "}
          Remove
        </p>
      </div>
      <hr className="mtb-10" />
    </div>
  );
};

export default CartItem;
